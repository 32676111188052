import React from 'react';
import { motion } from 'framer-motion';
import { 
  Shield, 
  TrendingUp, 
  Zap,
  Info,
  DollarSign,
  Clock,
  BarChart
} from 'lucide-react';
import { Card } from '../../../../components/ui/card';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../../../components/ui/tooltip';
import '../../../../styles/StockAI/RiskLevel.css';

const riskLevels = [
  {
    id: 'low',
    name: 'Low Risk',
    description: 'Stable, well-established companies with consistent returns',
    icon: Shield,
    color: 'blue',
    characteristics: [
      'Lower potential returns',
      'High market capitalization',
      'Strong dividend history',
      'Established market presence'
    ],
    timeHorizon: '5+ years',
    expectedReturn: '5-8%',
    volatility: 'Low',
    examples: ['Blue-chip stocks', 'Dividend aristocrats', 'Large-cap value stocks']
  },
  {
    id: 'medium',
    name: 'Medium Risk',
    description: 'Balance of growth potential and stability',
    icon: TrendingUp,
    color: 'yellow',
    characteristics: [
      'Moderate growth potential',
      'Mid-market capitalization',
      'Some market volatility',
      'Growing market share'
    ],
    timeHorizon: '3-5 years',
    expectedReturn: '8-12%',
    volatility: 'Moderate',
    examples: ['Growth stocks', 'Mid-cap stocks', 'Emerging market leaders']
  },
  {
    id: 'high',
    name: 'High Risk',
    description: 'High growth potential with significant volatility',
    icon: Zap,
    color: 'red',
    characteristics: [
      'High growth potential',
      'Small market capitalization',
      'High market volatility',
      'Emerging technologies'
    ],
    timeHorizon: '1-3 years',
    expectedReturn: '12%+',
    volatility: 'High',
    examples: ['Small-cap stocks', 'Tech startups', 'Emerging markets']
  }
];

const RiskLevel = ({ onSelect, loading }) => {
  const containerAnimation = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemAnimation = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  return (
    <TooltipProvider>
      <motion.div
        variants={containerAnimation}
        initial="hidden"
        animate="show"
        className="risk-level-container"
      >
        {/* Header */}
        <motion.div variants={itemAnimation} className="text-center mb-8">
          <h2 className="text-3xl font-bold text-white mb-2">Select Your Risk Level</h2>
          <p className="text-gray-400">
            Choose a risk profile that matches your investment goals
          </p>
        </motion.div>

        {/* Risk Level Cards */}
        <motion.div
          variants={itemAnimation}
          className="risk-levels-grid"
        >
          {riskLevels.map((level) => (
            <motion.div
              key={level.id}
              variants={itemAnimation}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <Card
                className={`p-6 cursor-pointer border-2 transition-all duration-300
                  ${level.color === 'blue' ? 'hover:border-blue-500/50 bg-blue-900/20' :
                    level.color === 'yellow' ? 'hover:border-yellow-500/50 bg-yellow-900/20' :
                    'hover:border-red-500/50 bg-red-900/20'}
                  border-gray-700`}
                onClick={() => !loading && onSelect(level.id)}
              >
                {/* Header */}
                <div className="flex items-center gap-3 mb-4">
                  <level.icon 
                    className={`
                      ${level.color === 'blue' ? 'text-blue-400' :
                        level.color === 'yellow' ? 'text-yellow-400' :
                        'text-red-400'}
                    `}
                    size={24}
                  />
                  <div>
                    <h3 className="text-xl font-semibold text-white">{level.name}</h3>
                    <p className="text-sm text-gray-400">{level.description}</p>
                  </div>
                </div>

                {/* Key Metrics */}
                <div className="grid grid-cols-2 gap-4 mb-4">
                  <div className="flex items-center gap-2">
                    <Clock className="text-gray-400" size={16} />
                    <span className="text-sm text-gray-300">
                      {level.timeHorizon}
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    <DollarSign className="text-gray-400" size={16} />
                    <span className="text-sm text-gray-300">
                      {level.expectedReturn}
                    </span>
                  </div>
                  <div className="flex items-center gap-2">
                    <BarChart className="text-gray-400" size={16} />
                    <span className="text-sm text-gray-300">
                      {level.volatility} Volatility
                    </span>
                  </div>
                </div>

                {/* Characteristics */}
                <div className="space-y-2">
                  <h4 className="text-sm font-medium text-gray-300">Key Characteristics:</h4>
                  <ul className="space-y-1">
                    {level.characteristics.map((char, index) => (
                      <li key={index} className="text-sm text-gray-400 flex items-start gap-2">
                        <span className="text-xs mt-1">•</span>
                        {char}
                      </li>
                    ))}
                  </ul>
                </div>

                {/* Examples */}
                <div className="mt-4">
                  <Tooltip>
                    <TooltipTrigger className="flex items-center gap-1 text-sm text-gray-400">
                      <Info size={14} />
                      <span>Get Stocks</span>
                    </TooltipTrigger>
                    <TooltipContent>
                      <ul className="space-y-1">
                        {level.examples.map((example, index) => (
                          <li key={index} className="text-sm">{example}</li>
                        ))}
                      </ul>
                    </TooltipContent>
                  </Tooltip>
                </div>
              </Card>
            </motion.div>
          ))}
        </motion.div>

        {/* Loading State */}
        {loading && (
          <motion.div
            className="fixed inset-0 bg-black/50 flex items-center justify-center z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <Card className="p-6 bg-gray-900 border-gray-700">
              <div className="text-center">
                <motion.div
                  animate={{ rotate: 360 }}
                  transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                  className="w-12 h-12 mb-4 mx-auto"
                >
                  <BarChart className="w-full h-full text-blue-400" />
                </motion.div>
                <p className="text-white">Analyzing market data...</p>
              </div>
            </Card>
          </motion.div>
        )}
      </motion.div>
    </TooltipProvider>
  );
};

export default RiskLevel;
