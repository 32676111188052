import React, { useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FiArrowLeft } from 'react-icons/fi';
import IndustrySelector from './components/IndustrySelector';
import RiskLevel from './components/RiskLevel';
import StockAnalysis from './components/Analysis/StockAnalysis';
import stockAIService from '../../utils/services/stockAIService';
import '../../styles/StockAI/StockAI.css';

const StockAI = () => {
  const navigate = useNavigate();
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [riskLevel, setRiskLevel] = useState(null);
  const [analysisResults, setAnalysisResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleIndustrySelect = (industry) => {
    setSelectedIndustry(industry);
    navigate('/stock-ai/risk-level');
  };

  const handleRiskSelect = async (risk) => {
    setRiskLevel(risk);
    setLoading(true);
    setError(null);

    try {
      console.log('Selected Industry:', selectedIndustry);
      console.log('Selected Risk Level:', risk);

      const results = await stockAIService.analyzeStocks({
        industry: selectedIndustry,
        riskLevel: risk
      });

      setAnalysisResults(results);
      navigate('/stock-ai/analysis');
    } catch (err) {
      console.error('Analysis Error:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleBackToChat = () => {
    navigate('/chat');
  };

  return (
    <div className="stock-ai-page">
      {/* Galaxy Background */}
      <div className="galaxy-background" />
      <div className="galaxy-spiral" />

      <div className="stock-ai-container">
        {/* Back Button */}
        <motion.button
          onClick={handleBackToChat}
          className="back-button"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <FiArrowLeft />
          <span>Back</span>
        </motion.button>

        <motion.div 
          className="stock-ai-header"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.div 
            className="stock-ai-logo"
            animate={{ rotate: 360 }}
            transition={{
              duration: 20,
              repeat: Infinity,
              ease: "linear"
            }}
          >
            <img src="/tax-logo-dark.svg" alt="Tax AI" />
          </motion.div>
          <h1>Stock AI Advisor</h1>
          <p>AI-Powered Stock Market Analysis & Recommendations</p>
        </motion.div>

        {error && (
          <motion.div 
            className="error-message"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            className="p-4 bg-red-500/20 border border-red-500 rounded-lg text-red-300 mx-4 mb-4"
          >
            <p className="text-sm">{error}</p>
            <p className="text-xs mt-2">
              Note: Risk levels have specific criteria for market cap, volume, and other metrics.
            </p>
          </motion.div>
        )}

        <AnimatePresence mode="wait">
          <Routes>
            <Route path="/" element={<Navigate to="/stock-ai/industry" replace />} />
            <Route 
              path="/industry" 
              element={
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.5 }}
                >
                  <IndustrySelector onSelect={handleIndustrySelect} />
                </motion.div>
              } 
            />
            <Route 
              path="/risk-level" 
              element={
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.5 }}
                >
                  <RiskLevel onSelect={handleRiskSelect} loading={loading} />
                </motion.div>
              } 
            />
            <Route 
              path="/analysis" 
              element={
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.5 }}
                >
                  <StockAnalysis 
                    results={analysisResults}
                    industry={selectedIndustry}
                    riskLevel={riskLevel}
                  />
                </motion.div>
              } 
            />
          </Routes>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default StockAI;
