import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { 
    FiArrowLeft,
    FiUser,
    FiInfo,
    FiFileText,
    FiUsers,
    FiMail,
    FiPhone,
    FiEdit,
    FiShield,
    FiX,
    FiSave,
    FiCheck
} from 'react-icons/fi';
import '../styles/Profile.css';
import API_URL, { ENDPOINTS } from '../utils/config/api';

// GalaxyBackground component remains exactly the same
const GalaxyBackground = () => {
    React.useEffect(() => {
        const createStars = () => {
            const starsContainer = document.createElement('div');
            starsContainer.className = 'stars-container';
            
            for (let i = 0; i < 200; i++) {
                const star = document.createElement('div');
                star.className = 'star';
                const size = Math.random() * 2;
                
                star.style.width = `${size}px`;
                star.style.height = `${size}px`;
                star.style.left = `${Math.random() * 100}%`;
                star.style.top = `${Math.random() * 100}%`;
                star.style.setProperty('--duration', `${2 + Math.random() * 3}s`);
                
                starsContainer.appendChild(star);
            }
            
            document.querySelector('.galaxy-background').appendChild(starsContainer);
        };

        createStars();

        return () => {
            const container = document.querySelector('.stars-container');
            if (container) {
                container.remove();
            }
        };
    }, []);

    return (
        <>
            <div className="galaxy-background" />
            <div className="galaxy-spiral" />
        </>
    );
};

const Profile = () => {
    const navigate = useNavigate();
    const [activeSection, setActiveSection] = useState('profile');
    const [isEditing, setIsEditing] = useState(false);
    const [message, setMessage] = useState({ text: '', type: '' });
    const [showPasswordChange, setShowPasswordChange] = useState(false);

    // Profile States
    const [profileData, setProfileData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        username: ''
    });

    // Password Change States
    const [passwordData, setPasswordData] = useState({
        current_password: '',
        new_password: '',
        confirm_password: ''
    });

    // Fetch Profile Data
    useEffect(() => {
        fetchProfileData();
    }, []);

    const fetchProfileData = async () => {
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.get(`${API_URL}${ENDPOINTS.PROFILE}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setProfileData(response.data);
        } catch (error) {
            setMessage({
                text: 'Failed to load profile data',
                type: 'error'
            });
        }
    };

    const handleProfileUpdate = async () => {
        try {
            const token = localStorage.getItem('access_token');
            await axios.put(`${API_URL}${ENDPOINTS.PROFILE}`, profileData, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setMessage({
                text: 'Profile updated successfully',
                type: 'success'
            });
            setIsEditing(false);
        } catch (error) {
            setMessage({
                text: error.response?.data?.detail || 'Failed to update profile',
                type: 'error'
            });
        }
    };

    const handlePasswordUpdate = async () => {
        if (passwordData.new_password !== passwordData.confirm_password) {
            setMessage({
                text: 'New passwords do not match',
                type: 'error'
            });
            return;
        }

        try {
            const token = localStorage.getItem('access_token');
            const formData = new FormData();
            formData.append('current_password', passwordData.current_password);
            formData.append('new_password', passwordData.new_password);

            await axios.put(`${API_URL}${ENDPOINTS.PROFILE}/security`, 
                formData,
                {
                    headers: { 
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            setMessage({
                text: 'Password updated successfully',
                type: 'success'
            });
            setPasswordData({
                current_password: '',
                new_password: '',
                confirm_password: ''
            });
            setShowPasswordChange(false);
        } catch (error) {
            setMessage({
                text: error.response?.data?.detail || 'Failed to update password',
                type: 'error'
            });
        }
    };

    // Rest of the component remains exactly the same
    return (
        <div className="profile-page">
            <GalaxyBackground />
            
            <motion.button 
                className="back-button"
                onClick={() => navigate('/chat')}
                whileHover={{ x: -5 }}
            >
                <FiArrowLeft size={24} />
                <span>Back to Chat</span>
            </motion.button>

            <div className="profile-container">
                <div className="profile-header">
                    <motion.img 
                        src="/tax-logo-dark.svg"
                        alt="TaxMeAI Logo"
                        className="profile-logo"
                        animate={{ rotate: 360 }}
                        transition={{
                            duration: 20,
                            repeat: Infinity,
                            ease: "linear"
                        }}
                    />
                    <h1>Profile & Settings</h1>
                </div>

                <div className="profile-content">
                    <div className="profile-navigation">
                        <button 
                            className={activeSection === 'profile' ? 'active' : ''}
                            onClick={() => setActiveSection('profile')}
                        >
                            <FiUser /> Profile
                        </button>
                        <button 
                            className={activeSection === 'about' ? 'active' : ''}
                            onClick={() => navigate('/about')}
                        >
                            <FiInfo /> About Us
                        </button>
                        <button 
                            className={activeSection === 'terms' ? 'active' : ''}
                            onClick={() => navigate('/terms-privacy')}
                        >
                            <FiFileText /> Terms & Privacy
                        </button>
                        <button 
                            className={activeSection === 'join' ? 'active' : ''}
                            onClick={() => navigate('/join-us')}
                        >
                            <FiUsers /> Join Us
                        </button>
                    </div>

                    <div className="profile-main">
                        <div className="profile-info-card">
                            <div className="card-header">
                                <h2><FiUser /> Personal Information</h2>
                                <button 
                                    className="edit-button"
                                    onClick={() => setIsEditing(!isEditing)}
                                >
                                    {isEditing ? (
                                        <><FiX /> Cancel</>
                                    ) : (
                                        <><FiEdit /> Edit</>
                                    )}
                                </button>
                            </div>
                            <div className="info-grid">
                                <div className="info-item">
                                    <label>First Name</label>
                                    {isEditing ? (
                                        <input
                                            type="text"
                                            value={profileData.first_name || ''}
                                            onChange={e => setProfileData({
                                                ...profileData,
                                                first_name: e.target.value
                                            })}
                                        />
                                    ) : (
                                        <p>{profileData.first_name || 'Not set'}</p>
                                    )}
                                </div>
                                <div className="info-item">
                                    <label>Last Name</label>
                                    {isEditing ? (
                                        <input
                                            type="text"
                                            value={profileData.last_name || ''}
                                            onChange={e => setProfileData({
                                                ...profileData,
                                                last_name: e.target.value
                                            })}
                                        />
                                    ) : (
                                        <p>{profileData.last_name || 'Not set'}</p>
                                    )}
                                </div>
                                <div className="info-item">
                                    <label><FiMail /> Email</label>
                                    <p>{profileData.email}</p>
                                </div>
                                <div className="info-item">
                                    <label><FiPhone /> Phone</label>
                                    {isEditing ? (
                                        <input
                                            type="tel"
                                            value={profileData.phone_number || ''}
                                            onChange={e => setProfileData({
                                                ...profileData,
                                                phone_number: e.target.value
                                            })}
                                        />
                                    ) : (
                                        <p>{profileData.phone_number || 'Not set'}</p>
                                    )}
                                </div>
                            </div>
                            {isEditing && (
                                <div className="edit-actions">
                                    <button 
                                        className="save-button"
                                        onClick={handleProfileUpdate}
                                    >
                                        <FiSave /> Save Changes
                                    </button>
                                </div>
                            )}
                        </div>

                        <div className="profile-security-card">
                            <div className="card-header">
                                <h2><FiShield /> Security Settings</h2>
                            </div>
                            {!showPasswordChange ? (
                                <div className="security-options">
                                    <button 
                                        className="security-button"
                                        onClick={() => setShowPasswordChange(true)}
                                    >
                                        Change Password
                                    </button>
                                </div>
                            ) : (
                                <div className="password-change-form">
                                    <div className="input-group">
                                        <label>Current Password</label>
                                        <input
                                            type="password"
                                            value={passwordData.current_password}
                                            onChange={e => setPasswordData({
                                                ...passwordData,
                                                current_password: e.target.value
                                            })}
                                        />
                                    </div>
                                    <div className="input-group">
                                        <label>New Password</label>
                                        <input
                                            type="password"
                                            value={passwordData.new_password}
                                            onChange={e => setPasswordData({
                                                ...passwordData,
                                                new_password: e.target.value
                                            })}
                                        />
                                    </div>
                                    <div className="input-group">
                                        <label>Confirm New Password</label>
                                        <input
                                            type="password"
                                            value={passwordData.confirm_password}
                                            onChange={e => setPasswordData({
                                                ...passwordData,
                                                confirm_password: e.target.value
                                            })}
                                        />
                                    </div>
                                    <div className="password-actions">
                                        <button 
                                            className="cancel-button"
                                            onClick={() => {
                                                setShowPasswordChange(false);
                                                setPasswordData({
                                                    current_password: '',
                                                    new_password: '',
                                                    confirm_password: ''
                                                });
                                            }}
                                        >
                                            <FiX /> Cancel
                                        </button>
                                        <button 
                                            className="save-button"
                                            onClick={handlePasswordUpdate}
                                        >
                                            <FiCheck /> Update Password
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {message.text && (
                    <motion.div
                        className={`message-toast ${message.type}`}
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 50 }}
                    >
                        {message.text}
                    </motion.div>
                )}
            </div>
        </div>
    );
};

export default Profile;
