// /frontend/src/components/ui/button.jsx
import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cn } from "../../utils/cn"

const Button = React.forwardRef(
  ({ className, variant = "default", size = "default", asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(
          "inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
          {
            // Default variant (semi-transparent)
            "bg-white/10 text-white hover:bg-white/20 border border-white/10": variant === "default",
            
            // Destructive variant
            "bg-red-500/10 text-red-400 hover:bg-red-500/20 border border-red-500/20": variant === "destructive",
            
            // Outline variant
            "border border-white/10 bg-transparent hover:bg-white/5 text-white": variant === "outline",
            
            // Ghost variant
            "hover:bg-white/5 text-white": variant === "ghost",
            
            // Primary variant
            "bg-blue-500/20 text-blue-100 hover:bg-blue-500/30 border border-blue-500/30": variant === "primary",
            
            // Secondary variant
            "bg-gray-600/20 text-gray-200 hover:bg-gray-600/30 border border-gray-600/30": variant === "secondary",

            // Link variant
            "text-blue-400 underline-offset-4 hover:underline": variant === "link",
          },
          {
            // Size variations
            "h-9 px-4 py-2 text-sm": size === "default",
            "h-7 rounded-md px-3 text-xs": size === "sm",
            "h-11 rounded-md px-8 text-base": size === "lg",
            "h-14 rounded-lg px-10 text-lg": size === "xl",
          },
          className
        )}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = "Button"

export { Button }
