// utils/stockAnalysis.js
export const formatNumber = (number, decimals = 2) => {
    if (!number && number !== 0) return 'N/A';
    return Number(number).toLocaleString(undefined, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    });
};

export const formatLargeNumber = (number) => {
    if (!number && number !== 0) return 'N/A';
    if (number >= 1e12) return `${(number / 1e12).toFixed(2)}T`;
    if (number >= 1e9) return `${(number / 1e9).toFixed(2)}B`;
    if (number >= 1e6) return `${(number / 1e6).toFixed(2)}M`;
    return formatNumber(number);
};

export const calculateTrend = (data, period = 14) => {
    if (!data || data.length < period) return null;
    const values = data.slice(-period).map(item => item.value);
    const trend = values[values.length - 1] - values[0];
    return {
        direction: trend > 0 ? 'up' : trend < 0 ? 'down' : 'neutral',
        percentage: Math.abs((trend / values[0]) * 100)
    };
};

export const getSignalStrength = (analysis) => {
    const weights = {
        moving_average: 0.3,
        volume: 0.2,
        rsi: 0.25,
        macd: 0.25
    };

    let score = 0;
    Object.entries(analysis.signals).forEach(([signal, value]) => {
        if (value.includes('buy') || value === 'oversold') {
            score += weights[signal];
        } else if (value.includes('sell') || value === 'overbought') {
            score -= weights[signal];
        }
    });

    return score;
};
