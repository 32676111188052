import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { motion } from 'framer-motion';
import { 
    FiLock, 
    FiUser, 
    FiDollarSign, 
    FiCalendar,
    FiGlobe,
    FiSave,
    FiBell,
    FiFileText,
    FiShield,
    FiArrowLeft
} from 'react-icons/fi';
import '../styles/Settings.css';
import API_URL, { ENDPOINTS } from '../utils/config/api';

const GalaxyBackground = () => {
    React.useEffect(() => {
        const createStars = () => {
            const starsContainer = document.createElement('div');
            starsContainer.className = 'stars-container';
            
            for (let i = 0; i < 200; i++) {
                const star = document.createElement('div');
                star.className = 'star';
                const size = Math.random() * 2;
                
                star.style.width = `${size}px`;
                star.style.height = `${size}px`;
                star.style.left = `${Math.random() * 100}%`;
                star.style.top = `${Math.random() * 100}%`;
                star.style.setProperty('--duration', `${2 + Math.random() * 3}s`);
                
                starsContainer.appendChild(star);
            }
            
            document.querySelector('.galaxy-background').appendChild(starsContainer);
        };

        createStars();

        return () => {
            const container = document.querySelector('.stars-container');
            if (container) {
                container.remove();
            }
        };
    }, []);

    return (
        <>
            <div className="galaxy-background" />
            <div className="galaxy-spiral" />
        </>
    );
};

const Settings = () => {
    const navigate = useNavigate();
    
    // Security Settings
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [message, setMessage] = useState("");

    // Tax Preferences
    const [taxYear, setTaxYear] = useState(new Date().getFullYear());
    const [filingStatus, setFilingStatus] = useState("single");
    const [currency, setCurrency] = useState("USD");
    const [dateFormat, setDateFormat] = useState("MM/DD/YYYY");

    // Notification Settings
    const [emailNotifications, setEmailNotifications] = useState(true);
    const [deadlineReminders, setDeadlineReminders] = useState(true);
    const [updateAlerts, setUpdateAlerts] = useState(true);

    // Document Settings
    const [autoSave, setAutoSave] = useState(true);
    const [defaultCategory, setDefaultCategory] = useState("business");

    // Active Tab State
    const [activeTab, setActiveTab] = useState("security");

    const handlePasswordUpdate = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem("access_token");
            const response = await axios.post(
                `${API_URL}${ENDPOINTS.UPDATE_PASSWORD}`,
                { oldPassword, newPassword },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setMessage({ text: response.data.message, type: 'success' });
        } catch (error) {
            console.error("Failed to update password:", error);
            setMessage({ text: "Failed to update password.", type: 'error' });
        }
    };

    const saveSettings = () => {
        setMessage({ text: "Settings saved successfully", type: 'success' });
        setTimeout(() => setMessage(""), 3000);
    };

    return (
        <div className="settings-page">
            <GalaxyBackground />
            
            <div className="settings-container">
                <motion.button 
                    className="back-button"
                    onClick={() => navigate('/chat')}
                    whileHover={{ x: -5 }}
                >
                    <FiArrowLeft size={24} />
                    <span>Back to Chat</span>
                </motion.button>

                <div className="settings-header">
                    <h1>Settings</h1>
                    <motion.button 
                        className="save-button"
                        onClick={saveSettings}
                    >
                        <FiSave size={20} />
                        Save Changes
                    </motion.button>
                </div>

                <div className="settings-content">
                    <div className="settings-tabs">
                        <button 
                            className={`tab ${activeTab === "security" ? "active" : ""}`}
                            onClick={() => setActiveTab("security")}
                        >
                            <FiShield size={20} />
                            <span>Security</span>
                        </button>
                        <button 
                            className={`tab ${activeTab === "tax" ? "active" : ""}`}
                            onClick={() => setActiveTab("tax")}
                        >
                            <FiDollarSign size={20} />
                            <span>Tax Preferences</span>
                        </button>
                        <button 
                            className={`tab ${activeTab === "notifications" ? "active" : ""}`}
                            onClick={() => setActiveTab("notifications")}
                        >
                            <FiBell size={20} />
                            <span>Notifications</span>
                        </button>
                        <button 
                            className={`tab ${activeTab === "documents" ? "active" : ""}`}
                            onClick={() => setActiveTab("documents")}
                        >
                            <FiFileText size={20} />
                            <span>Documents</span>
                        </button>
                    </div>

                    <div className="settings-panel">
                        {activeTab === "security" && (
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.2 }}
                                className="panel-content"
                            >
                                <h2>
                                    <FiShield size={24} />
                                    Security Settings
                                </h2>
                                <form onSubmit={handlePasswordUpdate}>
                                    <div className="input-group">
                                        <label>
                                            <FiLock size={16} />
                                            Current Password
                                        </label>
                                        <input
                                            type="password"
                                            value={oldPassword}
                                            onChange={(e) => setOldPassword(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="input-group">
                                        <label>
                                            <FiLock size={16} />
                                            New Password
                                        </label>
                                        <input
                                            type="password"
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <button type="submit">Update Password</button>
                                </form>
                            </motion.div>
                        )}

                        {activeTab === "tax" && (
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.2 }}
                                className="panel-content"
                            >
                                <h2>
                                    <FiDollarSign size={24} />
                                    Tax Preferences
                                </h2>
                                <div className="settings-grid">
                                    <div className="input-group">
                                        <label>
                                            <FiCalendar size={16} />
                                            Tax Year
                                        </label>
                                        <select 
                                            value={taxYear} 
                                            onChange={(e) => setTaxYear(e.target.value)}
                                        >
                                            <option value="2024">2024</option>
                                            <option value="2023">2023</option>
                                            <option value="2022">2022</option>
                                        </select>
                                    </div>
                                    <div className="input-group">
                                        <label>
                                            <FiUser size={16} />
                                            Filing Status
                                        </label>
                                        <select 
                                            value={filingStatus} 
                                            onChange={(e) => setFilingStatus(e.target.value)}
                                        >
                                            <option value="single">Single</option>
                                            <option value="married_joint">Married Filing Jointly</option>
                                            <option value="married_separate">Married Filing Separately</option>
                                            <option value="head">Head of Household</option>
                                        </select>
                                    </div>
                                    <div className="input-group">
                                        <label>
                                            <FiGlobe size={16} />
                                            Currency
                                        </label>
                                        <select 
                                            value={currency} 
                                            onChange={(e) => setCurrency(e.target.value)}
                                        >
                                            <option value="USD">USD ($)</option>
                                            <option value="EUR">EUR (€)</option>
                                            <option value="GBP">GBP (£)</option>
                                        </select>
                                    </div>
                                    <div className="input-group">
                                        <label>
                                            <FiCalendar size={16} />
                                            Date Format
                                        </label>
                                        <select 
                                            value={dateFormat} 
                                            onChange={(e) => setDateFormat(e.target.value)}
                                        >
                                            <option value="MM/DD/YYYY">MM/DD/YYYY</option>
                                            <option value="DD/MM/YYYY">DD/MM/YYYY</option>
                                            <option value="YYYY-MM-DD">YYYY-MM-DD</option>
                                        </select>
                                    </div>
                                </div>
                            </motion.div>
                        )}

                        {activeTab === "notifications" && (
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.2 }}
                                className="panel-content"
                            >
                                <h2>
                                    <FiBell size={24} />
                                    Notification Preferences
                                </h2>
                                <div className="settings-switches">
                                    <div className="switch-group">
                                        <label>
                                            <FiBell size={16} />
                                            Email Notifications
                                        </label>
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                checked={emailNotifications}
                                                onChange={(e) => setEmailNotifications(e.target.checked)}
                                            />
                                            <span className="slider" />
                                        </label>
                                    </div>
                                    <div className="switch-group">
                                        <label>
                                            <FiCalendar size={16} />
                                            Deadline Reminders
                                        </label>
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                checked={deadlineReminders}
                                                onChange={(e) => setDeadlineReminders(e.target.checked)}
                                            />
                                            <span className="slider" />
                                        </label>
                                    </div>
                                    <div className="switch-group">
                                        <label>
                                            <FiBell size={16} />
                                            Tax Law Updates
                                        </label>
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                checked={updateAlerts}
                                                onChange={(e) => setUpdateAlerts(e.target.checked)}
                                            />
                                            <span className="slider" />
                                        </label>
                                    </div>
                                </div>
                            </motion.div>
                        )}

                        {activeTab === "documents" && (
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.2 }}
                                className="panel-content"
                            >
                                <h2>
                                    <FiFileText size={24} />
                                    Document Settings
                                </h2>
                                <div className="settings-grid">
                                    <div className="switch-group">
                                        <label>
                                            <FiSave size={16} />
                                            Auto-Save Documents
                                        </label>
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                checked={autoSave}
                                                onChange={(e) => setAutoSave(e.target.checked)}
                                            />
                                            <span className="slider" />
                                        </label>
                                    </div>
                                    <div className="input-group">
                                        <label>
                                            <FiFileText size={16} />
                                            Default Document Category
                                        </label>
                                        <select 
                                            value={defaultCategory} 
                                            onChange={(e) => setDefaultCategory(e.target.value)}
                                        >
                                            <option value="business">Business</option>
                                            <option value="personal">Personal</option>
                                            <option value="deductions">Deductions</option>
                                            <option value="investments">Investments</option>
                                        </select>
                                    </div>
                                </div>
                            </motion.div>
                        )}
                    </div>
                </div>

                {message && (
                    <motion.div 
                        className={`notification ${message.type}`}
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 50 }}
                        transition={{ duration: 0.2 }}
                    >
                        {message.text}
                    </motion.div>
                )}
            </div>
        </div>
    );
};

export default Settings;
