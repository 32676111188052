import React from 'react';
import { motion } from 'framer-motion';
import { TrendingUp, ChevronRight } from 'lucide-react';
import { Card } from '../../../../components/ui/card';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../../../../components/ui/tooltip';

const IndustryCard = ({ industry, isHovered, onSelect }) => {
  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      className="industry-card-container"
    >
      <Card
        onClick={() => onSelect(industry)}
        className={`
          p-6 cursor-pointer transition-all duration-300
          ${isHovered ? 'bg-gray-800/70' : 'bg-gray-900/50'} 
          border-gray-700 hover:border-blue-500/50
        `}
      >
        <div className="flex items-start justify-between">
          <div className="space-y-3">
            <div className="flex items-center gap-3">
              <span className="text-3xl">{industry.icon}</span>
              <div>
                <h3 className="text-xl font-semibold text-white">
                  {industry.name}
                </h3>
                <p className="text-sm text-gray-400">{industry.description}</p>
              </div>
            </div>

            <div className="space-y-2">
              <div className="flex items-center gap-2">
                <TrendingUp 
                  className={`${
                    industry.trendingScore > 80 
                      ? 'text-green-400' 
                      : industry.trendingScore > 70 
                        ? 'text-yellow-400' 
                        : 'text-red-400'
                  }`} 
                  size={16} 
                />
                <span className="text-sm text-gray-300">
                  Trend Score: {industry.trendingScore}/100
                </span>
              </div>

              <div className="flex flex-wrap gap-2">
                {industry.examples.map((company, index) => (
                  <Tooltip key={company}>
                    <TooltipTrigger>
                      <span className="px-2 py-1 text-xs rounded-full bg-gray-800 
                                   text-gray-300 border border-gray-700">
                        {company}
                      </span>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Major company in {industry.name}</p>
                    </TooltipContent>
                  </Tooltip>
                ))}
              </div>
            </div>
          </div>

          <ChevronRight 
            className={`transition-transform duration-300 text-gray-400
              ${isHovered ? 'translate-x-1' : ''}`} 
            size={24} 
          />
        </div>
      </Card>
    </motion.div>
  );
};

export default IndustryCard;
