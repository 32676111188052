import React from 'react';
import { Card } from '../../../../components/ui/card';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

import '../../../../styles/TaxCalculator/charts/WithholdingsChart.css';

const WithholdingsChart = ({ federal_withholdings, state_withholdings, formatCurrency }) => {
  const data = [
    {
      name: 'Federal',
      withholding: federal_withholdings
    },
    {
      name: 'State', 
      withholding: state_withholdings
    }
  ];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-gray-900 p-3 rounded-lg border border-gray-800 shadow-xl">
          <p className="text-gray-300 font-medium">{label}</p>
          <p className="text-green-400">Withheld: {formatCurrency(payload[0].value)}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <Card className="p-6 bg-gray-900/50 border-gray-700">
      <h4 className="text-lg font-semibold text-white mb-4">Withholdings Breakdown</h4>
      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
            <XAxis dataKey="name" stroke="#9CA3AF" />
            <YAxis stroke="#9CA3AF" />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="withholding" name="Withheld" fill="#10B981" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
};

export default WithholdingsChart;
