import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { HelpCircle } from 'lucide-react';
import { Card } from '../../../../components/ui/card';
import { 
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger 
} from '../../../../components/ui/tooltip';
import '../../../../styles/TaxCalculator/steps/BasicInfo.css';

import Layout from '../shared/Layout';
import { TaxCalculationService } from '../../../../utils/services/taxCalculationService';

const BasicInfo = ({ formData, setFormData, error }) => {
  const navigate = useNavigate();
  const { stateCode } = useParams();

  // Route guard - redirect to state selection if no state/year selected
  useEffect(() => {
    if (!formData.state || !formData.year) {
      navigate('/tax-calculator', { replace: true });
    }
  }, [formData.state, formData.year, navigate]);

  const filingStatuses = [
    {
      value: TaxCalculationService.getFilingStatuses().SINGLE,
      label: 'Single',
      description: 'For unmarried individuals',
      tooltip: "Choose this if you're not married and don't qualify for other filing statuses"
    },
    {
      value: TaxCalculationService.getFilingStatuses().MARRIED_JOINT,
      label: 'Married Filing Jointly',
      description: 'For married couples filing together',
      tooltip: 'Often provides the most tax advantages for married couples'
    },
    {
      value: TaxCalculationService.getFilingStatuses().MARRIED_SEPARATE,
      label: 'Married Filing Separately',
      description: 'For married individuals filing separate returns',
      tooltip: 'Might be beneficial in specific financial situations'
    },
    {
      value: TaxCalculationService.getFilingStatuses().HEAD_OF_HOUSEHOLD,
      label: 'Head of Household',
      description: 'For unmarried individuals supporting dependents',
      tooltip: 'Provides tax advantages for single parents or guardians'
    }
  ];

  const handleStatusSelect = (status) => {
    setFormData(prev => ({
      ...prev,
      filing_status: status,
      // Reset certain fields when filing status changes
      deductions: {
        ...prev.deductions,
        type: 'standard' // Reset to standard deduction when status changes
      }
    }));
  };

  const handleNext = () => {
    if (formData.filing_status) {
      navigate(`/tax-calculator/${stateCode}/income`, { replace: true });
    }
  };

  return (
    <Layout formData={formData} setFormData={setFormData}>
      <motion.div 
        className="space-y-6"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="flex items-center justify-between mb-6">
          <div className="space-y-1">
            <h3 className="text-2xl font-bold text-white">Filing Status</h3>
            <p className="text-gray-300">Select how you'll file your taxes</p>
          </div>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <HelpCircle className="text-gray-400" size={20} />
              </TooltipTrigger>
              <TooltipContent>
                <div className="space-y-2">
                  <p className="text-sm">Your filing status determines:</p>
                  <ul className="text-xs space-y-1">
                    <li>• Tax bracket ranges</li>
                    <li>• Standard deduction amount</li>
                    <li>• Credit eligibility</li>
                  </ul>
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>

        <div className="grid grid-cols-1 gap-4">
          {filingStatuses.map(({ value, label, description, tooltip }) => (
            <TooltipProvider key={value}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <motion.button
                    type="button"
                    onClick={() => handleStatusSelect(value)}
                    className={`
                      w-full p-6 rounded-xl border transition-all text-left
                      ${formData.filing_status === value 
                        ? 'border-blue-500 bg-blue-900/30 text-blue-300' 
                        : 'border-gray-700 hover:border-blue-500/50 bg-gray-900/50'}
                    `}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <div className="space-y-2">
                      <h4 className="font-semibold text-lg">{label}</h4>
                      <p className="text-sm text-gray-400">{description}</p>
                    </div>
                  </motion.button>
                </TooltipTrigger>
                <TooltipContent>
                  <p className="max-w-xs">{tooltip}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ))}
        </div>

        {/* State-Specific Info */}
        <Card className="p-4 bg-blue-900/30 border-blue-500/50">
          <div className="flex items-start gap-3">
            <HelpCircle className="text-blue-400 mt-1" size={20} />
            <div>
              <h4 className="text-sm font-medium text-blue-300">State Filing Requirements</h4>
              <p className="text-sm text-blue-200/70">
                {stateCode === 'CA' 
                  ? 'California requires married couples to use the same filing status as their federal return.' 
                  : 'North Carolina generally follows federal filing status requirements.'}
              </p>
            </div>
          </div>
        </Card>

        {error && (
          <div className="mt-4 p-4 bg-red-900/50 border border-red-500 rounded-lg text-red-300">
            {error}
          </div>
        )}

        <motion.div 
          className="flex justify-end mt-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          <motion.button
            onClick={handleNext}
            disabled={!formData.filing_status}
            className="px-6 py-3 rounded-lg bg-blue-600 text-white hover:bg-blue-700 
                     disabled:opacity-50 disabled:cursor-not-allowed"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Continue to Income
          </motion.button>
        </motion.div>
      </motion.div>
    </Layout>
  );
};

export default BasicInfo;
