// At the top of StockAnalysis.jsx, update the imports
import React, { useState, useMemo } from 'react'; // Remove useEffect if not used
import { motion } from 'framer-motion';
import html2pdf from 'html2pdf.js';
import { 
    CartesianGrid, 
    Tooltip as RechartsTooltip, 
    ResponsiveContainer,
    Area,
    AreaChart,
    XAxis,
    YAxis,
    BarChart,
    Bar
} from 'recharts';
import { 
    BarChart2, 
    Activity, 
    PieChart as PieChartIcon,
    TrendingUp, 
    TrendingDown,
    AlertTriangle,
    Download,
    HelpCircle
} from 'lucide-react';

import { Card } from '../../../../components/ui/card';
import { 
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger 
} from '../../../../components/ui/tooltip';
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger
} from '../../../../components/ui/tabs';

// Import components
import { StockChart } from './components/StockChart';
import { SignalIndicator } from './components/SignalIndicator';
import { TechnicalIndicators } from './components/TechnicalIndicators';
import { MarketOverview } from './components/MarketOverview';
import { LoadingState } from './components/LoadingState';
import { ErrorState } from './components/ErrorState';

// Import utils
import { formatNumber, formatLargeNumber, calculateTrend, getSignalStrength } from './utils/stockAnalysis';

import './styles/StockAnalysis.css';


const StockAnalysis = ({ results, industry, riskLevel }) => {
    // States
    const [selectedStock, setSelectedStock] = useState(null);
    const [activeTab, setActiveTab] = useState('overview');
    const [selectedTimeframe, setSelectedTimeframe] = useState('1M');

    // Process and limit to top 3 stocks
    const topStocks = useMemo(() => {
        if (!results?.recommendations) return [];
        return results.recommendations
            .sort((a, b) => b.volume - a.volume)
            .slice(0, 3);
    }, [results?.recommendations]);

    // Constants
    const TIMEFRAMES = useMemo(() => ['1D', '1W', '1M', '3M', '1Y'], []);

    const tabs = useMemo(() => [
        { 
            id: 'overview', 
            label: 'Overview', 
            icon: PieChartIcon,
            description: 'Market overview and top picks',
            tooltip: 'View overall market analysis and top stock recommendations'
        },
        { 
            id: 'technical', 
            label: 'Technical', 
            icon: Activity,
            description: 'Technical indicators and charts',
            tooltip: 'Analyze technical indicators and trading signals'
        },
        { 
            id: 'signals', 
            label: 'Signals', 
            icon: BarChart2,
            description: 'Trading signals and recommendations',
            tooltip: 'View detailed trading signals and AI recommendations'
        }
    ], []);

    // Utility functions
    const formatNumber = (number, decimals = 2) => {
        if (!number && number !== 0) return 'N/A';
        return number.toLocaleString(undefined, {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals
        });
    };

    const formatLargeNumber = (number) => {
        if (!number && number !== 0) return 'N/A';
        if (number >= 1e12) return `${(number / 1e12).toFixed(2)}T`;
        if (number >= 1e9) return `${(number / 1e9).toFixed(2)}B`;
        if (number >= 1e6) return `${(number / 1e6).toFixed(2)}M`;
        return formatNumber(number);
    };

    // Animation configurations
    const containerAnimation = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1,
                duration: 0.3
            }
        }
    };

    const itemAnimation = {
        hidden: { opacity: 0, y: 20 },
        show: { 
            opacity: 1, 
            y: 0,
            transition: {
                duration: 0.3
            }
        }
    };

// Stock Card Component
    const StockCard = ({ stock, analysis, onClick, selected }) => {
        const signalStrength = analysis?.signal_strength || 0;
        const signalColor = analysis?.overall_signal?.includes('BUY') 
            ? 'text-green-400' 
            : analysis?.overall_signal?.includes('SELL')
            ? 'text-red-400'
            : 'text-yellow-400';

        return (
            <Card 
                className={`stock-card p-6 bg-gray-900/50 border-gray-700 hover:border-blue-500/50 
                    transition-all duration-200 cursor-pointer
                    ${selected ? 'border-blue-500 shadow-lg shadow-blue-500/20' : ''}`}
                onClick={onClick}
            >
                <div className="flex justify-between items-start">
                    <div>
                        <h3 className="text-lg font-semibold text-white">{stock.name}</h3>
                        <p className="text-sm text-gray-400">{stock.symbol}</p>
                    </div>
                    <div className="text-right">
                        <p className="text-xl font-bold text-white">
                            ${formatNumber(stock.price)}
                        </p>
                        <p className={`text-sm flex items-center justify-end gap-1 ${
                            stock.change >= 0 ? 'text-green-400' : 'text-red-400'
                        }`}>
                            {stock.change >= 0 ? (
                                <TrendingUp size={16} />
                            ) : (
                                <TrendingDown size={16} />
                            )}
                            {Math.abs(stock.change).toFixed(2)}%
                        </p>
                    </div>
                </div>

                <div className="mt-4 grid grid-cols-2 gap-4">
                    <Tooltip>
                        <TooltipTrigger>
                            <div>
                                <p className="text-sm text-gray-400">Volume</p>
                                <p className="text-white">{formatLargeNumber(stock.volume)}</p>
                            </div>
                        </TooltipTrigger>
                        <TooltipContent>
                            <p>Trading volume in the last 24 hours</p>
                        </TooltipContent>
                    </Tooltip>
                    
                    <Tooltip>
                        <TooltipTrigger>
                            <div>
                                <p className="text-sm text-gray-400">52W Range</p>
                                <p className="text-white text-sm">
                                    ${formatNumber(stock.low52w)} - ${formatNumber(stock.high52w)}
                                </p>
                            </div>
                        </TooltipTrigger>
                        <TooltipContent>
                            <p>Price range over the last 52 weeks</p>
                        </TooltipContent>
                    </Tooltip>
                </div>

                {analysis && (
                    <div className="mt-4 border-t border-gray-700 pt-4">
                        <div className="flex justify-between items-center">
                            <Tooltip>
                                <TooltipTrigger>
                                    <span className="text-sm text-gray-400 flex items-center gap-1">
                                        Signal Strength
                                        <HelpCircle size={14} />
                                    </span>
                                </TooltipTrigger>
                                <TooltipContent>
                                    <p>Combined strength of all technical indicators</p>
                                </TooltipContent>
                            </Tooltip>
                            <div className="flex items-center gap-2">
                                <div className="h-2 w-24 bg-gray-700 rounded-full overflow-hidden">
                                    <div 
                                        className={`h-full rounded-full ${signalColor}`}
                                        style={{ 
                                            width: `${Math.min(Math.abs(signalStrength) * 100, 100)}%`,
                                            opacity: 0.8 
                                        }}
                                    />
                                </div>
                                <span className={`text-sm font-medium ${signalColor}`}>
                                    {analysis.overall_signal}
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </Card>
        );
    };

// Technical Indicators Component
    const TechnicalIndicatorsPanel = ({ stock, analysis }) => {
        if (!stock || !analysis) return null;

        const indicators = analysis.indicators;
        const signals = analysis.signals;

        const indicatorSections = [
            {
                title: 'Price Action',
                indicators: [
                    {
                        name: 'SMA 20',
                        value: formatNumber(indicators.sma_20),
                        signal: signals.moving_average,
                        tooltip: '20-day Simple Moving Average'
                    },
                    {
                        name: 'SMA 50',
                        value: formatNumber(indicators.sma_50),
                        signal: signals.moving_average,
                        tooltip: '50-day Simple Moving Average'
                    }
                ]
            },
            {
                title: 'Momentum',
                indicators: [
                    {
                        name: 'RSI',
                        value: formatNumber(indicators.rsi),
                        signal: signals.rsi,
                        tooltip: 'Relative Strength Index (14 periods)'
                    },
                    {
                        name: 'MACD',
                        value: formatNumber(indicators.macd),
                        signal: signals.macd,
                        tooltip: 'Moving Average Convergence Divergence'
                    }
                ]
            }
        ];

        return (
            <div className="space-y-6">
                {indicatorSections.map((section, idx) => (
                    <Card key={idx} className="p-6 bg-gray-900/50 border-gray-700">
                        <h3 className="text-lg font-semibold text-white mb-4">{section.title}</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {section.indicators.map((indicator, i) => (
                                <Tooltip key={i}>
                                    <TooltipTrigger className="w-full">
                                        <div className="flex justify-between items-center p-3 bg-gray-800/50 rounded-lg">
                                            <span className="text-gray-400">{indicator.name}</span>
                                            <div className="flex items-center gap-2">
                                                <span className="text-white">{indicator.value}</span>
                                                <SignalIndicator signal={indicator.signal} />
                                            </div>
                                        </div>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        <p>{indicator.tooltip}</p>
                                    </TooltipContent>
                                </Tooltip>
                            ))}
                        </div>
                    </Card>
                ))}
            </div>
        );
    };

    // Signal Indicator Component
    const SignalIndicator = ({ signal }) => {
        const getSignalColor = (signal) => {
            switch (signal?.toLowerCase()) {
                case 'buy':
                case 'strong_buy':
                case 'oversold':
                    return 'text-green-400';
                case 'sell':
                case 'strong_sell':
                case 'overbought':
                    return 'text-red-400';
                default:
                    return 'text-yellow-400';
            }
        };

        return (
            <span className={`font-medium ${getSignalColor(signal)}`}>
                {signal?.toUpperCase()}
            </span>
        );
    };

// Chart Components
    const StockChart = ({ data, timeframe }) => {
        if (!data || data.length === 0) {
            return (
                <div className="flex items-center justify-center h-[400px] bg-gray-900/50 rounded-lg">
                    <div className="text-center">
                        <Activity className="w-12 h-12 text-gray-600 mx-auto mb-2" />
                        <p className="text-gray-400">No chart data available</p>
                    </div>
                </div>
            );
        }

        return (
            <ResponsiveContainer width="100%" height={400}>
                <AreaChart data={data}>
                    <defs>
                        <linearGradient id="colorStock" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#3B82F6" stopOpacity={0.3}/>
                            <stop offset="95%" stopColor="#3B82F6" stopOpacity={0}/>
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                    <XAxis 
                        dataKey="date" 
                        stroke="#9CA3AF"
                        tickFormatter={(timestamp) => {
                            const date = new Date(timestamp);
                            return timeframe === '1D' 
                                ? date.toLocaleTimeString() 
                                : date.toLocaleDateString();
                        }}
                    />
                    <YAxis 
                        stroke="#9CA3AF"
                        tickFormatter={(value) => `$${formatNumber(value)}`}
                    />
                    <RechartsTooltip
                        contentStyle={{
                            backgroundColor: '#1F2937',
                            border: '1px solid #374151',
                            borderRadius: '0.5rem'
                        }}
                        labelFormatter={(label) => {
                            const date = new Date(label);
                            return timeframe === '1D'
                                ? date.toLocaleTimeString()
                                : date.toLocaleDateString();
                        }}
                        formatter={(value) => [`$${formatNumber(value)}`, 'Price']}
                    />
                    <Area
                        type="monotone"
                        dataKey="value"
                        stroke="#3B82F6"
                        fillOpacity={1}
                        fill="url(#colorStock)"
                    />
                </AreaChart>
            </ResponsiveContainer>
        );
    };

const VolumeChart = ({ data }) => {
        return (
            <ResponsiveContainer width="100%" height={100}>
                <BarChart data={data}>
                    <XAxis 
                        dataKey="date" 
                        stroke="#9CA3AF"
                        tickFormatter={(timestamp) => new Date(timestamp).toLocaleDateString()}
                    />
                    <YAxis 
                        stroke="#9CA3AF"
                        tickFormatter={(value) => formatLargeNumber(value)}
                    />
                    <Tooltip
                        contentStyle={{
                            backgroundColor: '#1F2937',
                            border: '1px solid #374151',
                            borderRadius: '0.5rem'
                        }}
                        formatter={(value) => [formatLargeNumber(value), 'Volume']}
                    />
                    <Bar 
                        dataKey="volume" 
                        fill="#3B82F6" 
                        opacity={0.5}
                    />
                </BarChart>
            </ResponsiveContainer>
        );
    };

    // AI Insights Component
    const AIInsights = ({ predictions, symbol }) => {
        const stockPrediction = predictions?.stock_analyses?.[symbol];
        
        if (!stockPrediction) return null;

        return (
            <Card className="p-6 bg-gray-900/50 border-gray-700">
                <h3 className="text-lg font-semibold text-white mb-4">AI Analysis</h3>
                <div className="space-y-4">
                    {Object.entries(stockPrediction).map(([category, insights]) => (
                        <div key={category}>
                            <h4 className="text-md font-medium text-gray-300 capitalize mb-2">
                                {category.replace('_', ' ')}
                            </h4>
                            <ul className="space-y-2">
                                {insights.map((insight, idx) => (
                                    <li key={idx} className="text-gray-400">
                                        • {insight.title}
                                        {insight.description && (
                                            <p className="text-sm text-gray-500 ml-4 mt-1">
                                                {insight.description}
                                            </p>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </Card>
        );
    };

// Main Render
    return (
        <TooltipProvider>
            <motion.div
                id="stock-analysis-content"
                variants={containerAnimation}
                initial="hidden"
                animate="show"
                className="stock-analysis"
            >
                {/* Disclaimer Banner */}
                <motion.div 
                    variants={itemAnimation}
                    className="mb-6 p-4 bg-yellow-900/30 border border-yellow-500/50 rounded-lg"
                >
                    <div className="flex items-center gap-2">
                        <AlertTriangle className="text-yellow-500 shrink-0" size={20} />
                        <p className="text-sm text-yellow-300">
                            These recommendations are AI-powered estimates based on technical analysis. 
                            Always conduct your own research and consult with a financial advisor before 
                            making investment decisions.
                        </p>
                    </div>
                </motion.div>

                {/* Main Content Grid */}
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                    {/* Main Content Area */}
                    <div className="lg:col-span-2 space-y-6">
                        {/* Tabs Navigation */}
                        <Tabs defaultValue={activeTab} onValueChange={setActiveTab}>
                            <TabsList className="bg-gray-800/50 p-1 rounded-lg">
                                {tabs.map((tab) => (
                                    <Tooltip key={tab.id}>
                                        <TooltipTrigger>
                                            <TabsTrigger
                                                value={tab.id}
                                                className="flex items-center gap-2"
                                            >
                                                <tab.icon size={18} />
                                                <span className="hidden sm:inline">{tab.label}</span>
                                            </TabsTrigger>
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            <p>{tab.tooltip}</p>
                                        </TooltipContent>
                                    </Tooltip>
                                ))}
                            </TabsList>

                            <div className="mt-6">
                                <TabsContent value="overview">
                                    <motion.div variants={itemAnimation} className="space-y-6">
                                        {/* Market Overview */}
                                        <Card className="p-6 bg-gray-900/50 border-gray-700">
                                            <h3 className="text-lg font-semibold text-white mb-4">
                                                Market Overview
                                            </h3>
                                            <MarketOverview results={results} />
                                        </Card>

                                        {/* Top 3 Stocks */}
                                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                                            {topStocks.map((stock) => (
                                                <StockCard
                                                    key={stock.symbol}
                                                    stock={stock}
                                                    analysis={results.technical_analysis[stock.symbol]}
                                                    onClick={() => setSelectedStock(stock)}
                                                    selected={selectedStock?.symbol === stock.symbol}
                                                />
                                            ))}
                                        </div>
                                    </motion.div>
                                </TabsContent>

                                <TabsContent value="technical">
                                    <TechnicalIndicatorsPanel 
                                        stock={selectedStock}
                                        analysis={selectedStock ? results.technical_analysis[selectedStock.symbol] : null}
                                    />
                                </TabsContent>

                                <TabsContent value="signals">
                                    <AIInsights 
                                        predictions={results.ai_predictions}
                                        symbol={selectedStock?.symbol}
                                    />
                                </TabsContent>
                            </div>
                        </Tabs>
                    </div>

                    {/* Sidebar */}
                    <div className="hidden lg:block">
                        <div className="sidebar-sticky">
                            {selectedStock && (
                                <Card className="p-6 bg-gray-900/50 border-gray-700">
                                    <div className="space-y-6">
                                        {/* Stock Header */}
                                        <div className="flex justify-between items-start">
                                            <div>
                                                <h3 className="text-xl font-semibold text-white">
                                                    {selectedStock.name}
                                                </h3>
                                                <p className="text-gray-400">{selectedStock.symbol}</p>
                                            </div>
                                            <div className="text-right">
                                                <p className="text-2xl font-bold text-white">
                                                    ${formatNumber(selectedStock.price)}
                                                </p>
                                                <p className={`text-sm flex items-center justify-end gap-1 ${
                                                    selectedStock.change >= 0 ? 'text-green-400' : 'text-red-400'
                                                }`}>
                                                    {selectedStock.change >= 0 ? (
                                                        <TrendingUp size={16} />
                                                    ) : (
                                                        <TrendingDown size={16} />
                                                    )}
                                                    {Math.abs(selectedStock.change).toFixed(2)}%
                                                </p>
                                            </div>
                                        </div>

                                        {/* Chart Controls */}
                                        <div className="flex gap-2">
                                            {TIMEFRAMES.map((timeframe) => (
                                                <button
                                                    key={timeframe}
                                                    onClick={() => setSelectedTimeframe(timeframe)}
                                                    className={`px-3 py-1 rounded-lg text-sm font-medium transition-all duration-200 
                                                        ${selectedTimeframe === timeframe 
                                                            ? 'bg-blue-600 text-white' 
                                                            : 'bg-gray-800/50 text-gray-400 hover:bg-gray-700'}`}
                                                >
                                                    {timeframe}
                                                </button>
                                            ))}
                                        </div>

                                        {/* Price Chart */}
                                        <StockChart 
                                            data={selectedStock.historical_data}
                                            timeframe={selectedTimeframe}
                                        />

                                        {/* Volume Chart */}
                                        <VolumeChart data={selectedStock.historical_data} />
                                    </div>
                                </Card>
                            )}
                        </div>
                    </div>
                </div>

                {/* Export Button */}
                <motion.button
                    variants={itemAnimation}
                    onClick={() => {
                        const element = document.getElementById('stock-analysis-content');
                        html2pdf()
                            .set({
                                margin: 1,
                                filename: `${industry}-stock-analysis.pdf`,
                                image: { type: 'jpeg', quality: 0.98 },
                                html2canvas: { scale: 2 },
                                jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
                            })
                            .from(element)
                            .save();
                    }}
                    className="fixed bottom-6 right-6 px-4 py-2 bg-blue-600 text-white 
                             rounded-lg shadow-lg flex items-center gap-2 hover:bg-blue-700
                             transition-colors duration-200"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    <Download size={20} />
                    <span className="hidden sm:inline">Export Analysis</span>
                </motion.button>
            </motion.div>
        </TooltipProvider>
    );
};

export default StockAnalysis;
