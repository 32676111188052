import React from 'react';
import { DollarSign, Briefcase } from 'lucide-react';
import { Card } from '../../../components/ui/card';
import '../../../styles/TaxCalculator/components/W2Summary.css';

const W2Summary = ({ w2_info }) => {
    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(amount);
    };

    // Create a single-item array if w2_info is an object
    const w2Array = [
        {
            box1: w2_info.federal,
            box2: w2_info.federal,
            box17: w2_info.state
        }
    ];

    return (
        <Card className="p-6 bg-gray-900/50 border-gray-700 mb-6">
            <div className="flex items-center gap-2 mb-4">
                <Briefcase className="text-blue-400" size={24} />
                <h3 className="text-lg font-semibold text-white">W-2 Income Summary</h3>
            </div>
            
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
                {w2Array.map((w2, index) => (
                    <div 
                        key={index}
                        className="p-4 bg-gray-800/50 rounded-lg border border-gray-700"
                    >
                        <div className="text-sm text-gray-400 mb-2">Form #{index + 1}</div>
                        <div className="space-y-2">
                            <div className="flex justify-between items-center">
                                <span className="text-gray-300">Wages</span>
                                <span className="text-white font-medium">{formatCurrency(w2.box1)}</span>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-gray-300">Federal Tax Withheld</span>
                                <span className="text-green-400 font-medium">{formatCurrency(w2.box2)}</span>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-gray-300">State Tax Withheld</span>
                                <span className="text-green-400 font-medium">{formatCurrency(w2.box17)}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Card>
    );
};

export default W2Summary;
