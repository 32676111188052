import axios from 'axios';
import { ENDPOINTS } from '../config/api';

class StockAIService {
  constructor() {
    this.baseURL = 'https://taxmeai.com'; // Set base URL for the API
  }

  // Analyze stocks based on parameters (industry and risk level)
  async analyzeStocks(params) {
    try {
      const token = localStorage.getItem('access_token');

      // Transform the parameters to match API expectations
      const transformedParams = {
        industry: typeof params.industry === 'object' ? params.industry.id : params.industry,
        risk_level: typeof params.riskLevel === 'object' ? params.riskLevel.id : params.riskLevel,
      };

      console.log('Analyzing stocks with params:', transformedParams);

      // Make the POST request to the correct analyze endpoint
      const response = await axios.post(`${this.baseURL}${ENDPOINTS.STOCK_BASE}/analyze`, transformedParams, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      return response.data;
    } catch (error) {
      console.error('Stock analysis error:', error.response?.data);

      if (error.response?.status === 404) {
        throw new Error(
          `No stocks found matching the selected criteria. Try a different industry or risk level combination. Selected: ${params.industry.name || params.industry} industry with ${params.riskLevel || params.riskLevel.name} risk.`
        );
      }

      throw new Error(error.response?.data?.detail || 'Failed to analyze stocks');
    }
  }

  // Fetch industry data
  async getIndustryData() {
    try {
      const token = localStorage.getItem('access_token');

      const response = await axios.get(`${this.baseURL}${ENDPOINTS.INDUSTRY}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error('Error fetching industry data:', error.response?.data);
      throw new Error(error.response?.data?.detail || 'Failed to fetch industry data');
    }
  }

  // Fetch stock details based on symbol
  async getStockDetails(symbol) {
    try {
      const token = localStorage.getItem('access_token');

      const response = await axios.get(`${this.baseURL}${ENDPOINTS.STOCK.replace('{symbol}', symbol)}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error('Error fetching stock details:', error.response?.data);
      throw new Error(error.response?.data?.detail || 'Failed to fetch stock details');
    }
  }

  // Analyze market based on parameters (industry and risk level)
  async getMarketAnalysis(params) {
    try {
      const token = localStorage.getItem('access_token');

      const response = await axios.post(`${this.baseURL}${ENDPOINTS.MARKET_ANALYSIS}`, params, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      return response.data;
    } catch (error) {
      console.error('Error analyzing market data:', error.response?.data);
      throw new Error(error.response?.data?.detail || 'Failed to analyze market data');
    }
  }
}

// Create and export an instance of the service
const stockAIService = new StockAIService();
export { stockAIService };
export default stockAIService;

