// src/utils/config/api.js

// Base API URL - no trailing slash
const API_URL = 'https://taxmeai.com';

// Service-specific endpoints
export const ENDPOINTS = {
    // Auth endpoints
    LOGIN: '/login',
    SIGNUP: '/signup',
    VERIFY: '/verify',
    RESEND_VERIFICATION: '/resend-verification',
    PROFILE: '/profile',
    GOOGLE_AUTH: '/auth/google',

    // Stock AI endpoints
    STOCK_BASE: '/stock-ai',
    RISK_LEVEL: '/stock-ai/risk-levels',
    INDUSTRY: '/stock-ai/industry/{industry}',
    STOCK: '/stock-ai/stock/{symbol}',
    MARKET_ANALYSIS: '/stock-ai/market-analysis',

    // Tax calculator endpoints
    BASE: '/api/tax',
    CALCULATE_CA: '/api/tax/calculate/california',
    CALCULATE_NC: '/api/tax/calculate/north-carolina',
    HISTORY: '/history',
    CALC_ID: '/api/tax/calculations/{calc_id}',
    TAX_SUMMARY: '/api/tax/summary/{calc_id}',

    // Chat endpoints
    CHAT: '/chat',
    CHAT_HISTORY: '/history',
    UPLOAD_DOCUMENT: '/upload-document'
};

// Tax calculation constants
export const TAX_CONSTANTS = {
    SUPPORTED_STATES: {
        CA: {
            name: 'California',
            hasStateTax: true,
            description: 'California uses a progressive tax system with nine tax brackets.',
            features: ['State tax credits', 'Renter\'s credit', 'Social security exempt']
        },
        NC: {
            name: 'North Carolina',
            hasStateTax: true,
            description: 'North Carolina uses a flat tax rate system.',
            features: ['Flat tax rate', 'Limited credits', 'Follows federal rules']
        }
    },
    TAX_YEARS: {
        CURRENT: 2024,
        AVAILABLE: [2024, 2023, 2022, 2021, 2020]
    },
    FILING_STATUSES: {
        SINGLE: 'single',
        MARRIED_JOINT: 'married_joint',
        MARRIED_SEPARATE: 'married_separate',
        HEAD_OF_HOUSEHOLD: 'head_of_household'
    }
};

export default API_URL;
