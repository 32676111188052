// Verification.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import '../styles/Verification.css';
import API_URL, { ENDPOINTS } from '../utils/config/api';

function Verification() {
  const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  
  // Get email from either state or query params
  const email = location.state?.email || new URLSearchParams(location.search).get('email');

  useEffect(() => {
    if (!email) {
      navigate('/signup');
    }
  }, [email, navigate]);

  const handleCodeChange = (index, value) => {
    if (/^[0-9]*$/.test(value)) { // Only allow numbers
      if (value.length <= 1) {
        const newCode = [...verificationCode];
        newCode[index] = value;
        setVerificationCode(newCode);
        
        // Auto-focus next input
        if (value && index < 5) {
          document.getElementById(`code-${index + 1}`).focus();
        }
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !verificationCode[index] && index > 0) {
      document.getElementById(`code-${index - 1}`).focus();
    }
  };

  const handleVerify = async () => {
    const code = verificationCode.join('');
    if (code.length !== 6) {
      setMessage('Please enter the complete verification code');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}${ENDPOINTS.VERIFY}`, {
        params: {
          code: parseInt(code, 10)  // Convert string to integer
        }
      });

      if (response.data.access_token) {
        localStorage.setItem('access_token', response.data.access_token);
        toast.success('Email verified successfully!');
        navigate('/chat');
      }
    } catch (error) {
      console.error('Verification error:', error);
      setMessage(error.response?.data?.detail || 'Verification failed. Please try again.');
      toast.error('Verification failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleResendCode = async () => {
    try {
      setMessage('Resending verification code...');
      await axios.post(`${API_URL}${ENDPOINTS.RESEND_VERIFICATION}`, { email });
      toast.success('New verification code sent!');
      setMessage('New verification code has been sent to your email.');
    } catch (error) {
      console.error('Resend error:', error);
      toast.error('Failed to resend verification code.');
      setMessage('Failed to resend verification code. Please try again.');
    }
  };

  return (
    <div className="verification-page">
      <div className="galaxy-background"></div>
      <div className="galaxy-spiral"></div>

      <div className="verification-container">
        <h1>Verify Your Email</h1>
        <p className="verification-info">
          We've sent a verification code to<br />
          <strong>{email}</strong>
        </p>

        <div className="code-inputs">
          {verificationCode.map((digit, index) => (
            <input
              key={index}
              id={`code-${index}`}
              type="text"
              maxLength="1"
              value={digit}
              onChange={(e) => handleCodeChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              autoFocus={index === 0}
              pattern="[0-9]*"
              inputMode="numeric"
            />
          ))}
        </div>

        {message && <div className="message">{message}</div>}

        <button 
          onClick={handleVerify} 
          disabled={loading || verificationCode.join('').length !== 6}
          className="verify-button"
        >
          {loading ? 'Verifying...' : 'Verify Email'}
        </button>

        <div className="resend-code">
          Didn't receive the code? {' '}
          <button 
            className="resend-button" 
            onClick={handleResendCode}
            disabled={loading}
          >
            Resend
          </button>
        </div>
      </div>
    </div>
  );
}

export default Verification;
