import React from 'react';
import { Card } from '../../../../components/ui/card';
import { 
    AreaChart, 
    Area, 
    XAxis, 
    YAxis, 
    CartesianGrid, 
    Tooltip, 
    ResponsiveContainer 
} from 'recharts';
import '../../../../styles/TaxCalculator/charts/BracketBreakdownChart.css';

const BracketBreakdownChart = ({ federal_brackets, state_brackets, formatCurrency, formatPercentage }) => {
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-gray-900 p-3 rounded-lg border border-gray-800 shadow-xl">
          <p className="text-gray-300 font-medium">Income Range:</p>
          <p className="text-white">{label}</p>
          <div className="mt-2">
            <p className="text-blue-400">Rate: {formatPercentage(payload[0].value)}</p>
            <p className="text-green-400">Tax: {formatCurrency(payload[0].payload.tax)}</p>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <Card className="p-6 bg-gray-900/50 border-gray-700">
      <div className="space-y-6">
        <div>
          <h4 className="text-lg font-semibold text-white mb-4">Federal Tax Brackets</h4>
          <div className="h-48">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={federal_brackets}>
                <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                <XAxis dataKey="min" stroke="#9CA3AF" tickFormatter={formatCurrency} /> 
                <YAxis stroke="#9CA3AF" tickFormatter={formatPercentage} />
                <Tooltip content={<CustomTooltip />} />
                <Area type="monotone" dataKey="rate" fill="#3B82F6" stroke="#60A5FA" />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div>
          <h4 className="text-lg font-semibold text-white mb-4">State Tax Brackets</h4>
          <div className="h-48">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={state_brackets}>
                <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                <XAxis dataKey="min" stroke="#9CA3AF" tickFormatter={formatCurrency} />
                <YAxis stroke="#9CA3AF" tickFormatter={formatPercentage} />  
                <Tooltip content={<CustomTooltip />} />
                <Area type="monotone" dataKey="rate" fill="#10B981" stroke="#34D399" />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default BracketBreakdownChart;
