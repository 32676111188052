import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { DollarSign, HelpCircle, Home, Receipt, Landmark, Heart } from 'lucide-react';
import '../../../../styles/TaxCalculator/steps/Deductions.css';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../../../components/ui/tooltip';
import { Card } from '../../../../components/ui/card';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip as RechartsTooltip
} from 'recharts';
import Layout from '../shared/Layout';

const Deductions = ({ formData, setFormData, error }) => {
  const navigate = useNavigate();
  const { stateCode } = useParams();

  // Route guard - redirect if prerequisites not met
  useEffect(() => {
    if (!formData.state || !formData.year || !formData.filing_status || !Object.values(formData.income).some(v => v > 0)) {
      navigate('/tax-calculator', { replace: true });
    }
  }, [formData, navigate]);

  const handleDeductionTypeChange = (type) => {
    setFormData(prev => ({
      ...prev,
      deductions: {
        ...prev.deductions,
        type,
        // Reset home ownership if switching to standard
        home_ownership: type === 'standard' ? null : prev.deductions.home_ownership
      }
    }));
  };

  const handleItemizedDeductionChange = (field, value) => {
    const numValue = parseFloat(value) || 0;
    setFormData(prev => ({
      ...prev,
      deductions: {
        ...prev.deductions,
        itemized: {
          ...prev.deductions.itemized,
          [field]: numValue
        }
      }
    }));
  };

  const handleHomeOwnershipChange = (field, value) => {
    const numValue = parseFloat(value) || 0;
    setFormData(prev => ({
      ...prev,
      deductions: {
        ...prev.deductions,
        home_ownership: {
          ...(prev.deductions.home_ownership || {}),
          [field]: numValue
        }
      }
    }));
  };

  const homeOwnershipFields = {
    mortgage_interest: {
      label: 'Mortgage Interest',
      tooltip: 'Interest paid on your primary residence mortgage',
      icon: Landmark
    },
    property_taxes: {
      label: 'Property Taxes',
      tooltip: 'Real estate taxes paid on your home',
      icon: Home
    },
    home_insurance: {
      label: 'Home Insurance',
      tooltip: 'Annual homeowner\'s insurance premiums',
      icon: Receipt
    },
    pmi_insurance: {
      label: 'PMI Insurance',
      tooltip: 'Private Mortgage Insurance premiums',
      icon: Receipt
    }
  };

  const itemizedFields = {
    medical: {
      label: 'Medical Expenses',
      tooltip: 'Out-of-pocket medical and dental expenses',
      icon: Receipt
    },
    state_local_tax: {
      label: 'State & Local Taxes',
      tooltip: 'State and local income or sales taxes paid',
      icon: Receipt
    },
    property_tax: {
      label: 'Other Property Tax',
      tooltip: 'Property taxes for other properties',
      icon: Home
    },
    charitable: {
      label: 'Charitable Contributions',
      tooltip: 'Donations to qualified organizations',
      icon: Heart
    }
  };

  const totalItemizedDeductions = Object.values(formData.deductions.itemized)
    .reduce((sum, val) => sum + (val || 0), 0);

  const totalHomeOwnership = formData.deductions.home_ownership
    ? Object.values(formData.deductions.home_ownership)
        .reduce((sum, val) => sum + (val || 0), 0)
    : 0;

  const standardDeductions = {
    single: 13850,
    married_joint: 27700,
    married_separate: 13850,
    head_of_household: 20800
  };

  const standardDeductionAmount = standardDeductions[formData.filing_status] || 12950;

  const comparisonData = [
    {
      name: 'Standard',
      amount: standardDeductionAmount
    },
    {
      name: 'Itemized',
      amount: totalItemizedDeductions + totalHomeOwnership
    }
  ];

  const handleNext = () => {
    navigate(`/tax-calculator/${stateCode}/credits`, { replace: true });
  };

  const handleBack = () => {
    navigate(`/tax-calculator/${stateCode}/income`, { replace: true });
  };

  return (
    <Layout formData={formData} setFormData={setFormData}>
      <TooltipProvider>
        <motion.div 
          className="space-y-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="flex items-center justify-between mb-6">
            <div className="space-y-1">
              <h3 className="text-2xl font-bold text-white">Tax Deductions</h3>
              <p className="text-gray-300">Choose your deduction method</p>
            </div>
            <Tooltip>
              <TooltipTrigger>
                <HelpCircle className="text-gray-400" size={20} />
              </TooltipTrigger>
              <TooltipContent>
                <p className="max-w-xs">
                  Choose between standard deduction or itemize your deductions with home ownership
                </p>
              </TooltipContent>
            </Tooltip>
          </div>

          {/* Deduction Type Selection */}
          <Card className="p-6 bg-gray-900/50 border-gray-700">
            <h4 className="text-lg font-semibold text-white mb-4">Select Deduction Type</h4>
            <div className="flex gap-4">
              <motion.button
                type="button"
                onClick={() => handleDeductionTypeChange('standard')}
                className={`
                  flex-1 py-3 px-4 rounded-lg border transition-all
                  ${formData.deductions.type === 'standard' 
                    ? 'border-blue-500 bg-blue-900/30 text-blue-300' 
                    : 'border-gray-700 text-gray-400'}
                `}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <div className="text-left">
                  <div className="font-semibold">Standard Deduction</div>
                  <div className="text-sm opacity-80">
                    ${standardDeductionAmount.toLocaleString()}
                  </div>
                </div>
              </motion.button>
              <motion.button
                type="button"
                onClick={() => handleDeductionTypeChange('itemized')}
                className={`
                  flex-1 py-3 px-4 rounded-lg border transition-all
                  ${formData.deductions.type === 'itemized' 
                    ? 'border-blue-500 bg-blue-900/30 text-blue-300' 
                    : 'border-gray-700 text-gray-400'}
                `}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <div className="text-left">
                  <div className="font-semibold">Itemized Deductions</div>
                  <div className="text-sm opacity-80">
                    ${(totalItemizedDeductions + totalHomeOwnership).toLocaleString()}
                  </div>
                </div>
              </motion.button>
            </div>

            {/* Deduction Comparison Chart */}
            <div className="h-64 mt-6">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={comparisonData}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                  <XAxis dataKey="name" stroke="#9CA3AF" />
                  <YAxis stroke="#9CA3AF" />
                  <RechartsTooltip 
                    contentStyle={{ 
                      backgroundColor: '#1F2937', 
                      border: '1px solid #374151',
                      borderRadius: '0.5rem'
                    }}
                    formatter={(value) => `$${value.toLocaleString()}`}
                  />
                  <Bar 
                    dataKey="amount" 
                    fill="#60A5FA"
                    radius={[4, 4, 0, 0]}
                    label={{ 
                      position: 'top',
                      fill: '#9CA3AF',
                      formatter: (value) => `$${value.toLocaleString()}`
                    }}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Card>

          {/* Itemized Deductions Form */}
          {formData.deductions.type === 'itemized' && (
            <>
              {/* Regular Itemized Deductions */}
              <motion.div
                className="grid grid-cols-1 md:grid-cols-2 gap-6"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
              >
                {Object.entries(itemizedFields).map(([field, { label, tooltip, icon: Icon }]) => (
                  <Tooltip key={field}>
                    <TooltipTrigger asChild>
                      <motion.div 
                        className="space-y-2"
                        whileHover={{ scale: 1.02 }}
                      >
                        <label className="flex items-center gap-2 text-sm font-medium text-gray-300">
                          {label}
                          <Icon className="text-gray-400" size={16} />
                        </label>
                        <div className="relative">
                          <DollarSign className="absolute left-3 top-3 text-gray-400" size={16} />
                          <input
                            type="number"
                            value={formData.deductions.itemized[field] || ''}
                            onChange={(e) => handleItemizedDeductionChange(field, e.target.value)}
                            className="w-full rounded-lg border border-gray-700 bg-gray-900/50 
                                     py-3 pl-10 pr-4 text-white placeholder-gray-500"
                            min="0"
                            step="0.01"
                            placeholder="0.00"
                          />
                        </div>
                      </motion.div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p className="max-w-xs">{tooltip}</p>
                    </TooltipContent>
                  </Tooltip>
                ))}
              </motion.div>

              {/* Home Ownership Section */}
              <Card className="p-6 bg-gray-900/50 border-gray-700">
                <div className="flex items-center gap-2 mb-4">
                  <Home className="text-blue-400" size={24} />
                  <h4 className="text-lg font-semibold text-white">Home Ownership Deductions</h4>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {Object.entries(homeOwnershipFields).map(([field, { label, tooltip, icon: Icon }]) => (
                    <Tooltip key={field}>
                      <TooltipTrigger asChild>
                        <motion.div 
                          className="space-y-2"
                          whileHover={{ scale: 1.02 }}
                        >
                          <label className="flex items-center gap-2 text-sm font-medium text-gray-300">
                            {label}
                            <Icon className="text-gray-400" size={16} />
                          </label>
                          <div className="relative">
                            <DollarSign className="absolute left-3 top-3 text-gray-400" size={16} />
                            <input
                              type="number"
                              value={formData.deductions.home_ownership?.[field] || ''}
                              onChange={(e) => handleHomeOwnershipChange(field, e.target.value)}
                              className="w-full rounded-lg border border-gray-700 bg-gray-900/50 
                                       py-3 pl-10 pr-4 text-white placeholder-gray-500"
                              min="0"
                              step="0.01"
                              placeholder="0.00"
                            />
                          </div>
                        </motion.div>
                      </TooltipTrigger>
                      <TooltipContent>
                        <p className="max-w-xs">{tooltip}</p>
                      </TooltipContent>
                    </Tooltip>
                  ))}
                </div>
              </Card>
            </>
          )}

          {/* Additional Deductions */}
          <Card className="p-6 bg-gray-900/50 border-gray-700">
            <h4 className="text-lg font-semibold text-white mb-4">Additional Deductions</h4>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Student Loan Interest */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-300">
                  Student Loan Interest
                </label>
                <div className="relative">
                  <DollarSign className="absolute left-3 top-3 text-gray-400" size={16} />
                  <input
                    type="number"
                    value={formData.deductions.student_loan_interest || ''}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      deductions: {
                        ...prev.deductions,
                        student_loan_interest: parseFloat(e.target.value) || 0
                      }
                    }))}
                    className="w-full rounded-lg border border-gray-700 bg-gray-900/50 
                             py-3 pl-10 pr-4 text-white placeholder-gray-500"
                    min="0"
                    max="2500"
                    step="0.01"
                    placeholder="0.00"
                  />
                </div>
                <p className="text-xs text-gray-500">Maximum: $2,500</p>
              </div>

              {/* Self-Employed Health Insurance */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-300">
                  Self-Employed Health Insurance
                </label>
                <div className="relative">
                  <DollarSign className="absolute left-3 top-3 text-gray-400" size={16} />
                  <input
                    type="number"
                    value={formData.deductions.self_employed_health_insurance || ''}
                    onChange={(e) => setFormData(prev => ({
                      ...prev, deductions: {
                        ...prev.deductions,
                        self_employed_health_insurance: parseFloat(e.target.value) || 0
                      }
                    }))}
                    className="w-full rounded-lg border border-gray-700 bg-gray-900/50 
                             py-3 pl-10 pr-4 text-white placeholder-gray-500"
                    min="0"
                    step="0.01"
                    placeholder="0.00"
                  />
                </div>
              </div>

              {/* IRA Deduction */}
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-300">
                  IRA Deduction
                </label>
                <div className="relative">
                  <DollarSign className="absolute left-3 top-3 text-gray-400" size={16} />
                  <input
                    type="number"
                    value={formData.deductions.ira_deduction || ''}
                    onChange={(e) => setFormData(prev => ({
                      ...prev,
                      deductions: {
                        ...prev.deductions,
                        ira_deduction: parseFloat(e.target.value) || 0
                      }
                    }))}
                    className="w-full rounded-lg border border-gray-700 bg-gray-900/50 
                             py-3 pl-10 pr-4 text-white placeholder-gray-500"
                    min="0"
                    max="6000"
                    step="0.01"
                    placeholder="0.00"
                  />
                </div>
                <p className="text-xs text-gray-500">Maximum: $6,000</p>
              </div>
            </div>
          </Card>

          {/* Total Deductions Summary */}
          <Card className="p-6 bg-indigo-900/30 border-indigo-500/50">
            <div className="flex justify-between items-center">
              <div>
                <h4 className="text-lg font-semibold text-indigo-300">Total Deductions</h4>
                <p className="text-sm text-indigo-200/70">
                  {formData.deductions.type === 'standard' ? 'Standard Deduction' : 'Itemized with Home Ownership'}
                </p>
              </div>
              <div className="text-right">
                <p className="text-2xl font-bold text-indigo-300">
                  ${(formData.deductions.type === 'standard' 
                     ? standardDeductionAmount 
                     : totalItemizedDeductions + totalHomeOwnership).toLocaleString('en-US', { minimumFractionDigits: 2 })}
                </p>
              </div>
            </div>
          </Card>

          {error && (
            <div className="mt-4 p-4 bg-red-900/50 border border-red-500 rounded-lg text-red-300">
              {error}
            </div>
          )}

          {/* Navigation */}
          <motion.div 
            className="flex justify-between mt-8"
            variants={{ exit: { opacity: 0 } }}
          >
            <motion.button
              onClick={handleBack}
              className="px-6 py-3 rounded-lg border border-gray-700 text-gray-300 
                       hover:bg-gray-800"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Back
            </motion.button>
            <motion.button
              onClick={handleNext}
              className="px-6 py-3 rounded-lg bg-blue-600 text-white hover:bg-blue-700"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Continue to Credits
            </motion.button>
          </motion.div>
        </motion.div>
      </TooltipProvider>
    </Layout>
  );
};

export default Deductions;
