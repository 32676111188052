import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import { motion } from 'framer-motion';
import TaxYearSelector from '../TaxYearSelector';

import '../../../../styles/TaxCalculator/Layout.css';

const Layout = ({ children, formData = {}, setFormData }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const steps = [
    { name: 'Basic Info', path: '/tax-calculator/basic-info' },
    { name: 'Income', path: '/tax-calculator/income' },
    { name: 'Deductions', path: '/tax-calculator/deductions' },
    { name: 'Credits', path: '/tax-calculator/credits' }
  ];

  const isResultsPage = location.pathname.includes('/results');
  const isStateSelectionPage = location.pathname === '/tax-calculator';

  const handleYearChange = (year) => {
    setFormData(prev => ({
      ...prev,
      year: year
    }));
  };

  return (
    <div className="tax-calculator-content">
      {/* Back Button */}
      <motion.button
        className="tax-back-button"
        onClick={() => navigate('/chat')}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <FiArrowLeft size={20} />
        <span>Back</span>
      </motion.button>

      {/* Year Selector - Only show if not on state selection or results page */}
      {!isStateSelectionPage && !isResultsPage && (
        <TaxYearSelector 
          selectedYear={formData.year} 
          onChange={handleYearChange}
        />
      )}

      {/* Progress Steps - Only show if not on results page */}
      {!isResultsPage && !isStateSelectionPage && (
        <div className="tax-calculator-steps">
          {steps.map((step, index) => (
            <div 
              key={step.name} 
              className={`tax-step ${location.pathname.includes(step.path) ? 'active' : ''} 
                ${location.pathname > step.path ? 'completed' : ''}`}
            >
              <div className="step-number">{index + 1}</div>
              <span className="step-name">{step.name}</span>
            </div>
          ))}
        </div>
      )}

      {/* Main Content */}
      <div className="tax-calculator-form">
        {children}
      </div>
    </div>
  );
};

export default Layout;
