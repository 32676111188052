import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { FiCalendar, FiMapPin, FiHelpCircle, FiArrowRight } from 'react-icons/fi';

import { Card } from '../../../components/ui/card';
import { Dialog } from '../../../components/ui/dialog';
import { Button } from '../../../components/ui/button';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '../../../components/ui/tooltip';
import Layout from './shared/Layout';
import TaxYearSelector from './TaxYearSelector';
import { TaxCalculationService } from '../../../utils/services/taxCalculationService';
import '../../../styles/TaxCalculator/StateSelection.css';

const StateSelection = ({ formData, setFormData }) => {
    const navigate = useNavigate();

    const states = [
        {
            code: 'CA',
            name: 'California',
            description: 'Progressive tax system with nine brackets',
            features: [
                'Nine progressive tax brackets',
                'State-specific credits available',
                'Social security benefits exempt',
                'Home ownership deductions'
            ],
            available: true
        },
        {
            code: 'NC',
            name: 'North Carolina',
            description: 'Flat tax rate system',
            features: [
                'Single flat tax rate',
                'Standard deduction available',
                'Limited tax credits',
                'Home ownership deductions'
            ],
            available: true
        }
    ];

    const handleStateSelect = (stateCode) => {
        setFormData(prev => ({
            ...prev,
            state: stateCode
        }));
    };

    const handleContinue = () => {
        if (formData.state && formData.year) {
            const stateCode = formData.state.toLowerCase();
            navigate(`${stateCode}/basic-info`, { replace: true });
        }
    };

    const isValid = formData.state && formData.year;

    return (
        <Layout>
            <motion.div 
                className="space-y-6"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                <div className="mb-8">
                    <h2 className="text-2xl font-bold text-white">Calculate Your Taxes</h2>
                    <p className="text-gray-400">Select tax year and state to begin</p>
                </div>

                {/* Year Selection Card */}
                <Card className="p-6 bg-gray-900/50 border-gray-700">
                    <div className="flex items-center gap-3 mb-4">
                        <FiCalendar className="text-blue-400" size={24} />
                        <h3 className="text-lg font-semibold text-white">Tax Year</h3>
                    </div>
                    <TaxYearSelector 
                        selectedYear={formData.year} 
                        onChange={(year) => setFormData(prev => ({ ...prev, year }))}
                    />
                </Card>

                {/* State Selection */}
                <Card className="p-6 bg-gray-900/50 border-gray-700">
                    <div className="flex items-center gap-3 mb-4">
                        <FiMapPin className="text-blue-400" size={24} />
                        <h3 className="text-lg font-semibold text-white">Select State</h3>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {states.map((state) => (
                            <TooltipProvider key={state.code}>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <motion.button
                                            onClick={() => state.available && handleStateSelect(state.code)}
                                            className={`
                                                w-full p-6 rounded-xl border text-left transition-all
                                                ${state.available 
                                                    ? formData.state === state.code
                                                        ? 'border-blue-500 bg-blue-900/30'
                                                        : 'border-gray-700 hover:border-blue-500/50 bg-gray-900/50'
                                                    : 'border-gray-800 bg-gray-900/30 cursor-not-allowed'}
                                            `}
                                            whileHover={state.available ? { scale: 1.02 } : {}}
                                            whileTap={state.available ? { scale: 0.98 } : {}}
                                        >
                                            <div className="space-y-4">
                                                <div className="flex justify-between items-center">
                                                    <h3 className={`text-xl font-semibold ${state.available ? 'text-white' : 'text-gray-500'}`}>
                                                        {state.name}
                                                    </h3>
                                                    {state.available && (
                                                        <FiArrowRight className="text-gray-400" size={24} />
                                                    )}
                                                </div>
                                                <p className={`text-sm ${state.available ? 'text-gray-400' : 'text-gray-600'}`}>
                                                    {state.description}
                                                </p>
                                                {state.available && formData.state === state.code && (
                                                    <div className="mt-4 space-y-2">
                                                        {state.features.map((feature, index) => (
                                                            <div key={index} className="flex items-center text-sm text-gray-300">
                                                                <span className="w-2 h-2 bg-blue-400 rounded-full mr-2" />
                                                                {feature}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </motion.button>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        {state.available 
                                            ? <p>Calculate {state.name} state and federal taxes</p>
                                            : <p>{state.name} tax calculations coming soon</p>
                                        }
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        ))}
                    </div>
                </Card>

                {/* Continue Button */}
                <motion.button
                    onClick={handleContinue}
                    disabled={!isValid}
                    className={`w-full p-4 rounded-lg text-white font-semibold transition-all ${
                        isValid
                            ? 'bg-blue-600 hover:bg-blue-700'
                            : 'bg-gray-600 cursor-not-allowed opacity-50'
                    }`}
                    whileHover={isValid ? { scale: 1.02 } : {}}
                    whileTap={isValid ? { scale: 0.98 } : {}}
                >
                    Continue to Tax Calculator
                </motion.button>

                {/* Information Card */}
                <Card className="p-4 bg-blue-900/30 border-blue-500/50">
                    <div className="flex items-start gap-3">
                        <FiHelpCircle className="text-blue-400 mt-1" size={20} />
                        <div>
                            <h4 className="text-sm font-medium text-blue-300">Need Help?</h4>
                            <p className="text-sm text-blue-200/70">
                                Select your tax year and state to begin. The calculator will guide you 
                                through income, deductions, and credits to estimate your total tax liability.
                            </p>
                        </div>
                    </div>
                </Card>
            </motion.div>
        </Layout>
    );
};

export default StateSelection;
