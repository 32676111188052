import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';

import { Card } from '../../../../components/ui/card';
import '../../../../styles/TaxCalculator/charts/IncomeBreakdownChart.css';

const IncomeBreakdownChart = ({ income, total_tax, formatCurrency }) => {
  const COLORS = ['#60A5FA', '#34D399', '#FBBF24', '#A78BFA', '#F87171', '#6EE7B7', '#FCD34D'];

  const data = income;

  const totalIncome = data.reduce((sum, item) => sum + item.value, 0);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-gray-900 p-3 rounded-lg border border-gray-800 shadow-xl">
          <p className="text-gray-300">{payload[0].name}</p>
          <p className="text-white font-semibold">{formatCurrency(payload[0].value)}</p>
          <p className="text-gray-400 text-sm">
            {((payload[0].value / totalIncome) * 100).toFixed(1)}%
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <Card className="p-6 bg-gray-900/50 border-gray-700">
      <div className="flex justify-between items-start mb-4">
        <h4 className="text-lg font-semibold text-white">Income Breakdown</h4>
        <span className="text-sm text-gray-400">
          Total Tax: {formatCurrency(total_tax)}
        </span>
      </div>
      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={data}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={80}
              label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(1)}%`}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip content={<CustomTooltip />} />
            <Legend verticalAlign="bottom" height={36} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
};

export default IncomeBreakdownChart;
