import React from 'react';
import { Card } from '../../../components/ui/card';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../../components/ui/tooltip';
import { FiCalendar, FiHelpCircle } from 'react-icons/fi';
import { TaxCalculationService } from '../../../utils/services/taxCalculationService';
import '../../../styles/TaxCalculator/TaxYearSelector.css';

const TaxYearSelector = ({ selectedYear, onChange }) => {
  const currentYear = TaxCalculationService.getCurrentTaxYear();
  const availableYears = TaxCalculationService.getAvailableTaxYears();

  const getYearLabel = (year) => {
    if (year === currentYear) return `${year} (Tax Planning)`;
    if (year === currentYear - 1) return `${year} (Current Filing)`;
    return `${year}`;
  };

  const getTooltipContent = (year) => {
    if (year === currentYear) {
      return "Use this year for tax planning and estimating future taxes";
    }
    if (year === currentYear - 1) {
      return "Current tax filing year";
    }
    return "Previous tax years for late or amended returns";
  };

  return (
    <Card className="p-4 bg-gray-900/50 border-gray-700 mb-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-3">
          <FiCalendar className="text-blue-400" size={20} />
          <div>
            <h3 className="text-sm font-medium text-gray-200">Tax Year</h3>
            <p className="text-xs text-gray-400">
              {selectedYear === currentYear 
                ? "Tax planning mode" 
                : "Tax filing mode"}
            </p>
          </div>
        </div>

        <div className="flex items-center space-x-2">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <select
                  value={selectedYear}
                  onChange={(e) => onChange(parseInt(e.target.value))}
                  className="bg-gray-800 border border-gray-700 text-white rounded-lg px-4 py-2 
                           focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                >
                  {availableYears.map(year => (
                    <option 
                      key={year} 
                      value={year}
                      className="bg-gray-800 text-white"
                    >
                      {getYearLabel(year)}
                    </option>
                  ))}
                </select>
              </TooltipTrigger>
              <TooltipContent>
                <p className="text-sm">
                  {getTooltipContent(selectedYear)}
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <FiHelpCircle className="text-gray-400" size={16} />
              </TooltipTrigger>
              <TooltipContent>
                <div className="space-y-2">
                  <p className="text-sm">Select your tax year:</p>
                  <ul className="text-xs space-y-1">
                    <li>• Current Year ({currentYear}): For tax planning</li>
                    <li>• Last Year ({currentYear - 1}): For current filing</li>
                    <li>• Previous Years: For amended returns</li>
                  </ul>
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
    </Card>
  );
};

export default TaxYearSelector;
