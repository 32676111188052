// components/StockChart.jsx
import React from 'react';
import {
    CartesianGrid,
    Tooltip as RechartsTooltip,
    ResponsiveContainer,
    AreaChart,
    Area,
    XAxis,
    YAxis
} from 'recharts';
import { Activity } from 'lucide-react';

export const StockChart = ({ data, timeframe }) => {
    const formatNumber = (number, decimals = 2) => {
        if (!number && number !== 0) return 'N/A';
        return Number(number).toLocaleString(undefined, {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals
        });
    };

    if (!data || data.length === 0) {
        return (
            <div className="flex items-center justify-center h-[400px] bg-gray-900/50 rounded-lg">
                <div className="text-center">
                    <Activity className="w-12 h-12 text-gray-600 mx-auto mb-2" />
                    <p className="text-gray-400">No chart data available</p>
                </div>
            </div>
        );
    }

    return (
        <ResponsiveContainer width="100%" height={400}>
            <AreaChart data={data}>
                <defs>
                    <linearGradient id="colorStock" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#3B82F6" stopOpacity={0.3}/>
                        <stop offset="95%" stopColor="#3B82F6" stopOpacity={0}/>
                    </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                <XAxis 
                    dataKey="date" 
                    stroke="#9CA3AF"
                    tickFormatter={(timestamp) => {
                        const date = new Date(timestamp);
                        return timeframe === '1D' 
                            ? date.toLocaleTimeString() 
                            : date.toLocaleDateString();
                    }}
                />
                <YAxis 
                    stroke="#9CA3AF"
                    tickFormatter={(value) => `$${formatNumber(value)}`}
                />
                <RechartsTooltip
                    contentStyle={{
                        backgroundColor: '#1F2937',
                        border: '1px solid #374151',
                        borderRadius: '0.5rem'
                    }}
                    labelFormatter={(label) => {
                        const date = new Date(label);
                        return timeframe === '1D'
                            ? date.toLocaleTimeString()
                            : date.toLocaleDateString();
                    }}
                    formatter={(value) => [`$${formatNumber(value)}`, 'Price']}
                />
                <Area
                    type="monotone"
                    dataKey="value"
                    stroke="#3B82F6"
                    fillOpacity={1}
                    fill="url(#colorStock)"
                />
            </AreaChart>
        </ResponsiveContainer>
    );
};
