import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { 
    Download, 
    HelpCircle,
    TrendingUp,
    PiggyBank,
    LineChart,
    ArrowUpRight,
    DollarSign,
    Landmark
} from 'lucide-react';
import {
    Tooltip as UITooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '../../../components/ui/tooltip';
import { Card } from '../../../components/ui/card';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
    DialogFooter,
} from '../../../components/ui/dialog';
import { Button } from '../../../components/ui/button';
import { TaxCalculationService } from '../../../utils/services/taxCalculationService';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

// Import our separated components
import W2Summary from './W2Summary';
import IncomeBreakdownChart from './charts/IncomeBreakdownChart';
import WithholdingsChart from './charts/WithholdingsChart';
import BracketBreakdownChart from './charts/BracketBreakdownChart';

// Styles
import '../../../styles/TaxCalculator/TaxResults.css';

// Helper functions for data formatting
const formatIncomeData = (income) => {
    if (!income) return [];
    return Object.entries(income)
        .filter(([key, value]) => value && value !== '0' && key !== 'w2_info')
        .map(([key, value]) => ({
            name: key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
            value: parseFloat(value)
        }));
};

const prepareBracketData = (brackets) => {
    return Array.isArray(brackets) ? brackets : [];
};

// Investment Dialog Component
const InvestmentDialog = ({ refundAmount, onInvest }) => {
    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button 
                    className="w-full mt-4 bg-gradient-to-r from-green-500 to-blue-500 
                             hover:from-green-600 hover:to-blue-600 text-white border-0
                             shadow-lg hover:shadow-xl transition-all duration-300"
                >
                    <PiggyBank className="mr-2 h-5 w-5" />
                    Invest Your Tax Refund
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-md bg-gray-900/95 backdrop-blur-lg border border-gray-800">
                <DialogHeader>
                    <DialogTitle className="text-2xl font-bold text-white flex items-center gap-2">
                        <LineChart className="text-green-400" />
                        Maximize Your Refund with AI-Powered Investing
                    </DialogTitle>
                    <DialogDescription className="text-gray-300 mt-2">
                        Turn your ${refundAmount.toLocaleString()} tax refund into an investment opportunity
                    </DialogDescription>
                </DialogHeader>
                
                <div className="space-y-4 my-4">
                    <Card className="bg-blue-900/20 border-blue-500/30 p-4">
                        <h4 className="text-blue-300 font-semibold mb-2 flex items-center gap-2">
                            <TrendingUp className="h-4 w-4" />
                            Why Invest Your Refund?
                        </h4>
                        <ul className="space-y-2 text-sm text-blue-100/80">
                            <li className="flex items-center gap-2">
                                <DollarSign className="h-4 w-4 text-blue-400" />
                                AI-powered market analysis for smarter decisions
                            </li>
                            <li className="flex items-center gap-2">
                                <Landmark className="h-4 w-4 text-blue-400" />
                                Personalized stock recommendations based on risk profile
                            </li>
                            <li className="flex items-center gap-2">
                                <LineChart className="h-4 w-4 text-blue-400" />
                                Real-time market insights and trend analysis
                            </li>
                            <li className="flex items-center gap-2">
                                <TrendingUp className="h-4 w-4 text-blue-400" />
                                Portfolio diversification guidance
                            </li>
                        </ul>
                    </Card>

                    <div className="bg-green-900/20 border border-green-500/30 rounded-lg p-4">
                        <div className="flex items-center gap-2 text-green-400 mb-2">
                            <DollarSign className="h-5 w-5" />
                            <span className="font-semibold">Your Investment Potential</span>
                        </div>
                        <p className="text-sm text-gray-300">
                            Start your investment journey with your tax refund of{' '}
                            <span className="text-green-400 font-semibold">
                                ${refundAmount.toLocaleString()}
                            </span>
                        </p>
                    </div>
                </div>

                <DialogFooter className="gap-2 sm:gap-0">
                    <Button
                        variant="outline"
                        className="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 text-gray-300 border-gray-700"
                        onClick={() => window.open('https://www.investor.gov/introduction-investing', '_blank')}
                    >
                        Learn More
                    </Button>
                    <Button
                        className="w-full sm:w-auto bg-gradient-to-r from-green-500 to-blue-500 
                                 hover:from-green-600 hover:to-blue-600 text-white"
                        onClick={onInvest}
                    >
                        <ArrowUpRight className="mr-2 h-4 w-4" />
                        Explore Investment Options
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

// Main TaxResults Component
const TaxResults = ({ results, onRecalculate }) => {
    const navigate = useNavigate();
    const { stateCode } = useParams();
    
    if (!results || !results.federal_tax || !results.state_tax || !results.breakdown) {
        return <Navigate to="/tax-calculator" replace />;
    }

    const {
        federal_tax,
        state_tax,
        total_tax,
        effective_rate,
        summary,
        breakdown
    } = results;

    const formatCurrency = (amount) => {
        if (!amount) return '$0.00';
        return TaxCalculationService.formatCurrency(amount);
    };

    const formatPercentage = (value) => {
        if (!value) return '0.00%';
        return TaxCalculationService.formatPercentage(value);
    };

    const handleExportPDF = async () => {
        try {
            const element = document.getElementById('tax-results-content');
            if (!element) return;
            
            const canvas = await html2canvas(element);
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save('tax-estimate.pdf');
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    const handleInvestment = () => {
        navigate('/stock-ai');
    };

    return (
        <motion.div 
            id="tax-results-content"
            className="tax-results-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            {/* Tax Estimate Disclaimer */}
            <motion.div 
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                className="disclaimer-card"
            >
                <p className="text-yellow-300 text-sm">
                    This is an ESTIMATE based on the information provided. Actual tax liability may vary.
                    Please consult with a tax professional for precise calculations.
                </p>
            </motion.div>

            {/* W-2 Summary Section */}
            {breakdown?.federal?.withholdings && (
                <W2Summary w2_info={{
                    federal: breakdown.federal.withholdings.federal,
                    state: breakdown.federal.withholdings.state
                }} />
            )}

            {/* Main Summary Grid */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
                {/* Overall Tax Summary */}
                <Card className="p-6 col-span-full bg-blue-900/30 border-blue-500/50">
                    <div className="flex flex-col md:flex-row justify-between items-center">
                        <div>
                            <h3 className="text-2xl font-bold text-white">Final Tax Summary</h3>
                            <p className="text-gray-300">Tax Year {summary.year || new Date().getFullYear()}</p>
                            <p className="text-gray-300">
                                Filing as: {summary.filing_status?.replace(/_/g, ' ').toUpperCase() || 'N/A'}
                            </p>
                        </div>
                        <div className="text-right mt-4 md:mt-0">
                            {parseFloat(summary.refund_amount) > 0 ? (
                                <div className="text-green-400">
                                    <p className="text-xl">Tax Refund</p>
                                    <p className="text-3xl font-bold">
                                        +{formatCurrency(summary.refund_amount)}
                                    </p>
                                </div>
                            ) : (
                                <div className="text-red-400">
                                    <p className="text-xl">Tax Due</p>
                                    <p className="text-3xl font-bold">
                                        -{formatCurrency(summary.amount_due)}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </Card>
{/* Main Summary Grid */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
                {/* Income & Withholdings */}
                <Card className="p-6 bg-gray-900/50 border-gray-700">
                    <div className="flex justify-between items-start mb-4">
                        <h4 className="text-lg font-semibold text-white">Income & Withholdings</h4>
                        <TooltipProvider>
                            <UITooltip>
                                <TooltipTrigger>
                                    <HelpCircle className="text-gray-400" size={20} />
                                </TooltipTrigger>
                                <TooltipContent>
                                    <p className="max-w-xs">Total income and tax withholdings for the year</p>
                                </TooltipContent>
                            </UITooltip>
                        </TooltipProvider>
                    </div>
                    <div className="space-y-2">
                        <div className="flex justify-between">
                            <span className="text-gray-400">Total Income</span>
                            <span className="text-white">{formatCurrency(summary.total_income)}</span>
                        </div>
                        <div className="flex justify-between">
                            <span className="text-gray-400">Federal Withholdings</span>
                            <span className="text-white">
                                {formatCurrency(breakdown?.federal?.withholdings?.federal || 0)}
                            </span>
                        </div>
                        <div className="flex justify-between">
                            <span className="text-gray-400">State Withholdings</span>
                            <span className="text-white">
                                {formatCurrency(breakdown?.federal?.withholdings?.state || 0)}
                            </span>
                        </div>
                    </div>
                </Card>

                {/* Federal Tax Breakdown */}
                <Card className="p-6 bg-gray-900/50 border-gray-700">
                    <div className="flex justify-between items-start mb-4">
                        <h4 className="text-lg font-semibold text-white">Federal Tax</h4>
                        <span className="text-sm text-gray-400">
                            {formatPercentage(federal_tax.effective_rate)} Effective Rate
                        </span>
                    </div>
                    <div className="space-y-2">
                        <div className="flex justify-between">
                            <span className="text-gray-400">Taxable Income</span>
                            <span className="text-white">{formatCurrency(federal_tax.taxable_income)}</span>
                        </div>
                        <div className="flex justify-between">
                            <span className="text-gray-400">Tax Before Credits</span>
                            <span className="text-white">{formatCurrency(federal_tax.tax_before_credits)}</span>
                        </div>
                        <div className="flex justify-between">
                            <span className="text-gray-400">Credits Applied</span>
                            <span className="text-green-400">
                                -{formatCurrency(federal_tax.credits_applied)}
                            </span>
                        </div>
                        <div className="flex justify-between font-semibold">
                            <span className="text-gray-400">Final Federal Tax</span>
                            <span className="text-white">{formatCurrency(federal_tax.net_tax)}</span>
                        </div>
                    </div>
                </Card>

                {/* State Tax Breakdown */}
                <Card className="p-6 bg-gray-900/50 border-gray-700">
                    <div className="flex justify-between items-start mb-4">
                        <h4 className="text-lg font-semibold text-white">
                            {TaxCalculationService.getStateName(stateCode)} Tax
                        </h4>
                        <span className="text-sm text-gray-400">
                            {formatPercentage(state_tax.effective_rate)} Effective Rate
                        </span>
                    </div>
                    <div className="space-y-2">
                        <div className="flex justify-between">
                            <span className="text-gray-400">Taxable Income</span>
                            <span className="text-white">{formatCurrency(state_tax.taxable_income)}</span>
                        </div>
                        <div className="flex justify-between">
                            <span className="text-gray-400">Tax Before Credits</span>
                            <span className="text-white">{formatCurrency(state_tax.tax_before_credits)}</span>
                        </div>
                        <div className="flex justify-between">
                            <span className="text-gray-400">Credits Applied</span>
                            <span className="text-green-400">
                                -{formatCurrency(state_tax.credits_applied)}
                            </span>
                        </div>
                        <div className="flex justify-between font-semibold">
                            <span className="text-gray-400">Final State Tax</span>
                            <span className="text-white">{formatCurrency(state_tax.net_tax)}</span>
                        </div>
                    </div>
                </Card>
            </div>
           </div>
            {/* Charts Section */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                {/* Income Breakdown Chart */}
                {breakdown?.federal?.income && (
                    <IncomeBreakdownChart 
                        income={formatIncomeData(breakdown.federal.income)}
                        total_tax={total_tax}
                        formatCurrency={formatCurrency}
                    />
                )}

                {/* Withholdings Chart */}
                {breakdown?.federal?.withholdings && (
                    <WithholdingsChart 
                        federal_withholdings={breakdown.federal.withholdings.federal}
                        state_withholdings={breakdown.federal.withholdings.state}
                        formatCurrency={formatCurrency}
                    />
                )}

                {/* Bracket Breakdown Chart */}
                {(breakdown?.federal?.brackets_used || breakdown?.state?.brackets_used) && (
                    <BracketBreakdownChart
                        federal_brackets={prepareBracketData(breakdown?.federal?.brackets_used)}
                        state_brackets={prepareBracketData(breakdown?.state?.brackets_used)}
                        formatCurrency={formatCurrency}
                        formatPercentage={formatPercentage}
                    />
                )}
            </div>

            {/* Investment Recommendation for Refunds */}
            {parseFloat(summary.refund_amount) > 0 && (
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.3 }}
                >
                    <InvestmentDialog 
                        refundAmount={parseFloat(summary.refund_amount)}
                        onInvest={handleInvestment}
                    />
                </motion.div>
            )}

            {/* Action Buttons */}
            <div className="flex flex-col sm:flex-row justify-between gap-4 mt-8">
                <motion.button
                    onClick={handleExportPDF}
                    className="flex items-center justify-center px-6 py-3 rounded-lg 
                             bg-gradient-to-r from-blue-600 to-blue-700 text-white 
                             hover:from-blue-700 hover:to-blue-800 shadow-lg hover:shadow-xl
                             transition-all duration-300"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    <Download size={20} className="mr-2" />
                    Export as PDF
                </motion.button>

                <motion.button
                    onClick={onRecalculate}
                    className="px-6 py-3 rounded-lg border border-gray-700 text-gray-300 
                             hover:bg-gray-800 transition-colors duration-300"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                >
                    Calculate Another
                </motion.button>
            </div>
        </motion.div>
    );
};

export default TaxResults;
