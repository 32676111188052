import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import '../../styles/Legal.css';

const Privacy = () => {
    return (
        <div className="legal-page">
            <Link to="/" className="back-button">
                <ArrowLeft size={20} />
                Back to Home
            </Link>

            <div className="legal-container">
                <h1>Privacy Policy</h1>
                <div className="legal-content">
                    <section>
                        <h2>1. Information Collection</h2>
                        <p>We collect the following types of information:</p>
                        <ul>
                            <li>Personal information (name, email, phone number)</li>
                            <li>Financial data for tax and investment analysis</li>
                            <li>Usage data and interaction with our services</li>
                            <li>Device and browser information</li>
                        </ul>
                    </section>

                    <section>
                        <h2>2. Use of Information</h2>
                        <p>Your information is used to:</p>
                        <ul>
                            <li>Provide and improve our AI-powered services</li>
                            <li>Generate personalized financial insights</li>
                            <li>Enhance security and prevent fraud</li>
                            <li>Communicate service updates and offers</li>
                        </ul>
                    </section>

                    <section>
                        <h2>3. Data Protection</h2>
                        <p>We protect your data through:</p>
                        <ul>
                            <li>End-to-end encryption for sensitive information</li>
                            <li>Regular security audits and updates</li>
                            <li>Strict access controls and monitoring</li>
                            <li>Compliance with data protection regulations</li>
                        </ul>
                    </section>

                    <section>
                        <h2>4. Data Sharing</h2>
                        <p>We may share data with:</p>
                        <ul>
                            <li>Service providers who assist our operations</li>
                            <li>Legal authorities when required by law</li>
                            <li>Business partners with your explicit consent</li>
                            <li>Analytics providers in anonymous form</li>
                        </ul>
                    </section>

                    <section>
                        <h2>5. Your Rights</h2>
                        <p>You have the right to:</p>
                        <ul>
                            <li>Access your personal data</li>
                            <li>Request data correction or deletion</li>
                            <li>Opt-out of marketing communications</li>
                            <li>Export your data in a portable format</li>
                        </ul>
                    </section>

                    <section>
                        <h2>6. Cookies and Tracking</h2>
                        <p>Our cookie usage includes:</p>
                        <ul>
                            <li>Essential cookies for service functionality</li>
                            <li>Analytics cookies to improve user experience</li>
                            <li>Preference cookies to remember your settings</li>
                            <li>Third-party cookies for enhanced features</li>
                        </ul>
                    </section>

                    <section>
                        <h2>7. Children's Privacy</h2>
                        <p>Regarding users under 18:</p>
                        <ul>
                            <li>We do not knowingly collect data from minors</li>
                            <li>Parent/guardian consent is required</li>
                            <li>We will delete any inadvertently collected data</li>
                        </ul>
                    </section>

                    <section>
                        <h2>8. Changes to Policy</h2>
                        <p>Policy updates:</p>
                        <ul>
                            <li>We may update this policy periodically</li>
                            <li>Users will be notified of significant changes</li>
                            <li>Continued use implies acceptance of changes</li>
                        </ul>
                    </section>

                    <section>
                        <h2>9. Contact Us</h2>
                        <p>For privacy concerns:</p>
                        <ul>
                            <li>Email: privacy@taxmeai.com</li>
                            <li>Phone: [Your Phone Number]</li>
                            <li>Address: [Your Business Address]</li>
                        </ul>
                    </section>
                </div>

                <div className="legal-footer">
                    <p>Last updated: December 9, 2024</p>
                    <p>
                        Review our{' '}
                        <Link to="/terms" className="legal-link">Terms and Conditions</Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Privacy;
