import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Download, Trash2, Search, ArrowLeft } from "lucide-react";
import { toast } from "react-hot-toast";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import '../styles/History.css';
import API_URL, { ENDPOINTS } from '../utils/config/api';

const History = () => {
    const [history, setHistory] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const fetchHistory = async () => {
        try {
            const token = localStorage.getItem("access_token");
            const response = await axios.get(`${API_URL}${ENDPOINTS.HISTORY}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setHistory(response.data.items || []);
            setLoading(false);
        } catch (error) {
            console.error("Failed to fetch history:", error);
            if (error.response?.status === 401) {
                navigate("/login");
            } else {
                toast.error("Failed to load chat history");
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleBack = () => {
        navigate(-1);
    };

    const exportToPDF = () => {
        try {
            const doc = new jsPDF();
            
            // Add title
            doc.setFontSize(20);
            doc.text("Chat History", 14, 15);

            // Prepare data for table
            const tableData = history.map(item => [
                new Date(item.created_at).toLocaleString(),
                item.question,
                item.answer
            ]);

            // Add table
            doc.autoTable({
                startY: 25,
                head: [['Date', 'Question', 'Answer']],
                body: tableData,
                theme: 'grid',
                headStyles: { fillColor: [123, 47, 255] },
                columnStyles: {
                    0: { cellWidth: 40 },
                    1: { cellWidth: 70 },
                    2: { cellWidth: 80 }
                },
                styles: { overflow: 'linebreak', cellPadding: 2 }
            });

            // Save the PDF
            doc.save("chat_history.pdf");
            toast.success("History exported successfully!");
        } catch (error) {
            console.error("Failed to export history:", error);
            toast.error("Failed to export history");
        }
    };

    const clearHistory = async () => {
        if (window.confirm("Are you sure you want to clear your chat history? This action cannot be undone.")) {
            try {
                const token = localStorage.getItem("access_token");
                const response = await axios.get(`${API_URL}${ENDPOINTS.HISTORY}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });
                setHistory([]);
                toast.success("History cleared successfully!");
            } catch (error) {
                console.error("Failed to clear history:", error);
                toast.error("Failed to clear history");
            }
        }
    };

    const filteredHistory = history.filter(
        (item) =>
            item.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.answer.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="history-page">
            <div className="galaxy-background"></div>
            <div className="galaxy-spiral"></div>

            <button onClick={handleBack} className="back-button">
                <ArrowLeft size={20} />
                Back
            </button>

            <div className="history-container">
                <div className="history-header">
                    <h1>Chat History</h1>
                    <div className="history-actions">
                        <button 
                            className="action-button"
                            onClick={exportToPDF}
                            title="Export to PDF"
                        >
                            <Download size={20} />
                            Export PDF
                        </button>
                        <button 
                            className="action-button"
                            onClick={clearHistory}
                            title="Clear History"
                        >
                            <Trash2 size={20} />
                            Clear All
                        </button>
                    </div>
                </div>

                <div className="search-container">
                    <Search size={20} className="search-icon" />
                    <input
                        type="text"
                        placeholder="Search in history..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="search-input"
                    />
                </div>

                {loading ? (
                    <div className="history-loading">
                        <div className="loader"></div>
                        <p>Loading history...</p>
                    </div>
                ) : (
                    <div className="history-list">
                        {filteredHistory.length === 0 ? (
                            <div className="history-item">
                                <p>No chat history found</p>
                            </div>
                        ) : (
                            filteredHistory.map((item) => (
                                <div key={item.id} className="history-item">
                                    <div className="question">
                                        <span className="label">Q:</span>
                                        <p>{item.question}</p>
                                    </div>
                                    <div className="answer">
                                        <span className="label">A:</span>
                                        <p>{item.answer}</p>
                                    </div>
                                    <div className="timestamp">
                                        {new Date(item.created_at).toLocaleString()}
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default History;
