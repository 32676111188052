// MarketOverview.jsx
import { formatNumber, formatLargeNumber } from '../utils/stockAnalysis';

export const MarketOverview = ({ results }) => {
    const getMarketSentiment = () => {
        const buySignals = Object.values(results?.technical_analysis || {})
            .filter(analysis => analysis.overall_signal.includes('BUY')).length;
        const totalSignals = Object.keys(results?.technical_analysis || {}).length;
        
        if (buySignals / totalSignals > 0.6) return 'Bullish';
        if (buySignals / totalSignals < 0.4) return 'Bearish';
        return 'Neutral';
    };

    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-4">
                <div className="flex justify-between items-center">
                    <span className="text-gray-400">Market Sentiment</span>
                    <span className={`${
                        getMarketSentiment() === 'Bullish' ? 'text-green-400' :
                        getMarketSentiment() === 'Bearish' ? 'text-red-400' :
                        'text-yellow-400'
                    }`}>
                        {getMarketSentiment()}
                    </span>
                </div>
                <div className="flex justify-between items-center">
                    <span className="text-gray-400">Average Volume</span>
                    <span className="text-white">
                        {formatLargeNumber(results?.recommendations?.reduce((acc, stock) => 
                            acc + stock.volume, 0) / (results?.recommendations?.length || 1)
                        )}
                    </span>
                </div>
            </div>
            <div className="space-y-4">
                <div className="flex justify-between items-center">
                    <span className="text-gray-400">Buy Signals</span>
                    <span className="text-green-400">
                        {Object.values(results?.technical_analysis || {})
                            .filter(analysis => analysis.overall_signal.includes('BUY')).length}
                    </span>
                </div>
                <div className="flex justify-between items-center">
                    <span className="text-gray-400">Sell Signals</span>
                    <span className="text-red-400">
                        {Object.values(results?.technical_analysis || {})
                            .filter(analysis => analysis.overall_signal.includes('SELL')).length}
                    </span>
                </div>
            </div>
        </div>
    );
};
