import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Search } from 'lucide-react';
import IndustryCard from './IndustryCard';
import { Card } from '../../../../components/ui/card';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../../../components/ui/tooltip';
import '../../../../styles/StockAI/IndustrySelector.css';

const industries = [
  {
    id: 'technology',
    name: 'Technology',
    description: 'Software, Hardware, and IT services',
    icon: '💻',
    examples: ['Apple', 'Microsoft', 'Google'],
    trendingScore: 85
  },
  {
    id: 'finance',
    name: 'Financial Services',
    description: 'Banking, Insurance, and Investment services',
    icon: '💰',
    examples: ['JPMorgan Chase', 'Visa', 'Bank of America'],
    trendingScore: 78
  },
  {
    id: 'healthcare',
    name: 'Healthcare',
    description: 'Medical devices, Pharmaceuticals, and Healthcare services',
    icon: '🏥',
    examples: ['Johnson & Johnson', 'UnitedHealth', 'Pfizer'],
    trendingScore: 82
  },
  {
    id: 'energy',
    name: 'Energy',
    description: 'Oil & Gas, Renewable Energy, and Utilities',
    icon: '⚡',
    examples: ['ExxonMobil', 'Chevron', 'NextEra Energy'],
    trendingScore: 75
  },
  {
    id: 'consumer',
    name: 'Consumer Goods',
    description: 'Retail, Food & Beverage, and Personal goods',
    icon: '🛍️',
    examples: ['Walmart', 'Coca-Cola', 'Procter & Gamble'],
    trendingScore: 72
  },
  {
    id: 'real_estate',
    name: 'Real Estate',
    description: 'REITs, Property Development, and Management',
    icon: '🏢',
    examples: ['American Tower', 'Prologis', 'Crown Castle'],
    trendingScore: 70
  }
];

const IndustrySelector = ({ onSelect }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [hoveredIndustry, setHoveredIndustry] = useState(null);

  const filteredIndustries = industries.filter(industry =>
    industry.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    industry.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const containerAnimation = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemAnimation = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  return (
    <TooltipProvider>
      <motion.div
        variants={containerAnimation}
        initial="hidden"
        animate="show"
        className="industry-selector"
      >
        {/* Search Bar */}
        <motion.div variants={itemAnimation} className="search-container">
          <Card className="p-4 bg-gray-900/50 border-gray-700">
            <div className="relative">
              <Search className="absolute left-3 top-3 text-gray-400" size={20} />
              <input
                type="text"
                placeholder="Search industries..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 bg-gray-800/50 border border-gray-700 
                         rounded-lg text-white placeholder-gray-400 focus:border-blue-500
                         focus:outline-none focus:ring-2 focus:ring-blue-500/50"
              />
            </div>
          </Card>
        </motion.div>

        {/* Industries Grid */}
        <motion.div 
          variants={itemAnimation}
          className="industries-grid"
        >
          {filteredIndustries.map((industry) => (
            <motion.div
              key={industry.id}
              variants={itemAnimation}
              onHoverStart={() => setHoveredIndustry(industry.id)}
              onHoverEnd={() => setHoveredIndustry(null)}
            >
              <IndustryCard
                industry={industry}
                isHovered={hoveredIndustry === industry.id}
                onSelect={() => onSelect(industry)}
              />
            </motion.div>
          ))}
        </motion.div>

        {/* No Results Message */}
        {filteredIndustries.length === 0 && (
          <motion.div
            variants={itemAnimation}
            className="no-results"
          >
            <p className="text-gray-400 text-center mt-8">
              No industries found matching "{searchTerm}"
            </p>
          </motion.div>
        )}
      </motion.div>
    </TooltipProvider>
  );
};

export default IndustrySelector;
