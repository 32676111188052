import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { FiArrowLeft, FiUsers, FiBriefcase } from 'react-icons/fi';
import '../styles/JoinUs.css';

const GalaxyBackground = () => {
    React.useEffect(() => {
        const createStars = () => {
            const starsContainer = document.createElement('div');
            starsContainer.className = 'stars-container';
            
            for (let i = 0; i < 200; i++) {
                const star = document.createElement('div');
                star.className = 'star';
                const size = Math.random() * 2;
                
                star.style.width = `${size}px`;
                star.style.height = `${size}px`;
                star.style.left = `${Math.random() * 100}%`;
                star.style.top = `${Math.random() * 100}%`;
                star.style.setProperty('--duration', `${2 + Math.random() * 3}s`);
                
                starsContainer.appendChild(star);
            }
            
            document.querySelector('.galaxy-background').appendChild(starsContainer);
        };

        createStars();

        return () => {
            const container = document.querySelector('.stars-container');
            if (container) {
                container.remove();
            }
        };
    }, []);

    return (
        <>
            <div className="galaxy-background" />
            <div className="galaxy-spiral" />
        </>
    );
};

const JoinUs = () => {
    const navigate = useNavigate();

    return (
        <div className="join-us-page">
            <GalaxyBackground />
            
            <motion.button 
                className="back-button"
                onClick={() => navigate('/chat')}
                whileHover={{ x: -5 }}
            >
                <FiArrowLeft size={24} />
                <span>Back</span>
            </motion.button>

            <div className="join-us-container">
                <div className="join-us-header">
                    <motion.img 
                        src="/tax-logo-dark.svg"
                        alt="TaxMeAI Logo"
                        className="join-us-logo"
                        animate={{ rotate: 360 }}
                        transition={{
                            duration: 20,
                            repeat: Infinity,
                            ease: "linear"
                        }}
                    />
                    <h1>Join Our Team</h1>
                </div>

                <div className="join-us-content">
                    <section className="culture-section">
                        <h2><FiUsers /> Our Culture</h2>
                        <p>At TaxMeAI, we're building the future of tax services through innovation and collaboration. Our team combines expertise in AI, tax regulations, and customer service to create revolutionary solutions.</p>
                        
                        <div className="values-grid">
                            <div className="value-card">
                                <h3>Innovation First</h3>
                                <p>We encourage creative thinking and embrace new technologies</p>
                            </div>
                            <div className="value-card">
                                <h3>Collaborative Spirit</h3>
                                <p>Work alongside experts in AI, tax, and technology</p>
                            </div>
                            <div className="value-card">
                                <h3>Growth Mindset</h3>
                                <p>Continuous learning and professional development</p>
                            </div>
                            <div className="value-card">
                                <h3>Work-Life Balance</h3>
                                <p>Flexible schedules and remote work options</p>
                            </div>
                        </div>
                    </section>

                    <section className="openings-section">
                        <h2><FiBriefcase /> Open Positions</h2>
                        <div className="coming-soon">
                            <motion.div
                                animate={{ 
                                    scale: [1, 1.02, 1],
                                    opacity: [1, 0.8, 1]
                                }}
                                transition={{
                                    duration: 2,
                                    repeat: Infinity,
                                    ease: "easeInOut"
                                }}
                            >
                                <h3>Coming Soon</h3>
                                <p>We're preparing exciting opportunities. Check back later for job openings!</p>
                            </motion.div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default JoinUs;
