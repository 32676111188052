import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { GoogleLogin } from '@react-oauth/google';
import { toast } from 'react-hot-toast';
import '../styles/Login.css';
import axios from 'axios';
import API_URL, { ENDPOINTS } from '../utils/config/api';

const GalaxyBackground = () => {
    React.useEffect(() => {
        const createStars = () => {
            const starsContainer = document.createElement('div');
            starsContainer.className = 'stars-container';
            
            for (let i = 0; i < 200; i++) {
                const star = document.createElement('div');
                star.className = 'star';
                const size = Math.random() * 2;
                
                star.style.width = `${size}px`;
                star.style.height = `${size}px`;
                star.style.left = `${Math.random() * 100}%`;
                star.style.top = `${Math.random() * 100}%`;
                star.style.setProperty('--duration', `${2 + Math.random() * 3}s`);
                
                starsContainer.appendChild(star);
            }
            
            document.querySelector('.galaxy-background').appendChild(starsContainer);
        };

        createStars();

        return () => {
            const container = document.querySelector('.stars-container');
            if (container) {
                container.remove();
            }
        };
    }, []);

    return (
        <>
            <div className="galaxy-background" />
            <div className="galaxy-spiral" />
        </>
    );
};

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${API_URL}${ENDPOINTS.LOGIN}`, {
                email,
                password
            }, {
                withCredentials: true
            });

            toast.success('Login successful');
            localStorage.setItem('access_token', response.data.access_token);
            navigate('/chat');
        } catch (error) {
            console.error('Login error:', error);
            toast.error(error.response?.data?.detail || 'Login failed. Please check your credentials.');
        } finally {
            setLoading(false);
        }
    };

    const handleGoogleSuccess = async (credentialResponse) => {
        setLoading(true);
        try {
            const response = await axios.post(`${API_URL}${ENDPOINTS.GOOGLE_AUTH}`, {
                token: credentialResponse.credential
            });

            toast.success('Login successful');
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('user', JSON.stringify(response.data.user));
            navigate('/chat');
        } catch (error) {
            console.error('Google login error:', error);
            toast.error(error.response?.data?.detail || 'Google login failed');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="login-page">
            <GalaxyBackground />
            
            <div className="login-container">
                <motion.div 
                    className="login-card"
                    initial={{ scale: 0.9, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.3 }}
                >
                    <div className="logo-container">
                        <motion.img 
                            src="/tax-logo-dark.svg"
                            alt="TaxMe AI"
                            className="logo spinning"
                            animate={{ 
                                rotate: 360,
                            }}
                            transition={{
                                duration: 20,
                                repeat: Infinity,
                                ease: "linear",
                                repeatType: "loop"
                            }}
                        />
                    </div>
                    
                    <h1>Welcome to TaxMeAI</h1>
                    <p className="subtitle">Your AI-Powered Tax Assistant</p>

                    <div className="social-login">
                        <GoogleLogin
                            onSuccess={handleGoogleSuccess}
                            onError={() => {
                                toast.error('Google Sign In Failed');
                            }}
                            useOneTap={true}
                            theme="filled_black"
                            shape="pill"
                            text="continue_with"
                            size="large"
                            width="200"
                        />
                    </div>

                    <div className="divider">
                        <span>OR</span>
                    </div>

                    <form onSubmit={handleLogin}>
                        <div className="input-group">
                            <input
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                disabled={loading}
                            />
                        </div>

                        <div className="input-group">
                            <input
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                disabled={loading}
                            />
                        </div>

                        <motion.button
                            type="submit"
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                            className="solid-button"
                            disabled={loading}
                        >
                            {loading ? 'Signing in...' : 'Sign In'}
                        </motion.button>
                    </form>

                    <div className="divider">
                        <span>New to TaxMe AI?</span>
                    </div>

                    <Link to="/signup" className="signup-link">
                        Create an account
                    </Link>
                </motion.div>
            </div>
        </div>
    );
};

export default Login;
