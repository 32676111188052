import axios from 'axios';
import API_URL, { ENDPOINTS, TAX_CONSTANTS } from '../config/api';

// Helper function to convert numbers to string decimals
const convertToStringDecimals = (obj) => {
  if (!obj) return obj;

  const result = {};
  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'number') {
      result[key] = value.toString();
    } else if (Array.isArray(value)) {
      result[key] = value.map(item => 
        typeof item === 'object' ? convertToStringDecimals(item) : item
      );
    } else if (typeof value === 'object' && value !== null) {
      result[key] = convertToStringDecimals(value);
    } else {
      result[key] = value;
    }
  }
  return result;
};

// Format tax data for API consumption
const formatTaxData = (taxData) => ({
  year: taxData.year,
  state: taxData.state,
  filing_status: taxData.filing_status,
  income: {
    ...convertToStringDecimals(taxData.income),
    w2_info: taxData.income.w2_info?.map(w2 => ({
      box1: w2.box1.toString(),
      box2: w2.box2.toString(),
      box17: w2.box17.toString()
    }))
  },
  deductions: taxData.deductions
    ? {
        type: taxData.deductions.type,
        itemized: convertToStringDecimals(taxData.deductions.itemized),
        home_ownership: taxData.deductions.home_ownership 
          ? convertToStringDecimals(taxData.deductions.home_ownership)
          : null,
        student_loan_interest: taxData.deductions.student_loan_interest?.toString(),
        self_employed_health_insurance: taxData.deductions.self_employed_health_insurance?.toString(),
        ira_deduction: taxData.deductions.ira_deduction?.toString(),
      }
    : null,
  credits: taxData.credits
    ? {
        child_tax_credit: taxData.credits.child_tax_credit
          ? { num_children: taxData.credits.child_tax_credit.num_children }
          : null,
        child_care: taxData.credits.child_care
          ? { expenses: taxData.credits.child_care.expenses?.toString() }
          : null,
        retirement_savings: taxData.credits.retirement_savings
          ? {
              has_retirement_plan: taxData.credits.retirement_savings.has_retirement_plan,
              contributions: taxData.credits.retirement_savings.contributions?.toString(),
            }
          : null,
        education: taxData.credits.education
          ? {
              type: taxData.credits.education.type,
              expenses: taxData.credits.education.expenses?.toString(),
            }
          : null,
      }
    : null,
});

// Tax calculation service
export const TaxCalculationService = {
  // Base calculation method
  calculateTax: async (endpoint, taxData) => {
    try {
      const formattedData = formatTaxData(taxData);
      console.log(`Formatted tax data for ${endpoint}:`, formattedData);

      const response = await axios.post(
        `${API_URL}${endpoint}`,
        formattedData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error('Tax calculation error:', error);
      if (error.response) {
        throw new Error(
          error.response.data?.detail ||
            (typeof error.response.data === 'object'
              ? JSON.stringify(error.response.data)
              : 'Failed to calculate taxes')
        );
      }
      throw new Error('Network error occurred while calculating taxes');
    }
  },

  // Calculate California taxes
  calculateCaliforniaTax: async (taxData) => {
    return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_CA, {
      ...taxData,
      state: 'CA'
    });
  },

  // Calculate North Carolina taxes
  calculateNorthCarolinaTax: async (taxData) => {
    return TaxCalculationService.calculateTax(ENDPOINTS.CALCULATE_NC, {
      ...taxData,
      state: 'NC'
    });
  },

  // Fetch a specific tax calculation by ID
  getTaxCalculation: async (calculationId) => {
    try {
      const url = `${API_URL}${ENDPOINTS.CALC_ID.replace('{calc_id}', calculationId)}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error('Get tax calculation error:', error);
      if (error.response) {
        throw new Error(error.response.data?.detail || 'Failed to retrieve tax calculation');
      }
      throw new Error('Network error occurred while retrieving calculation');
    }
  },

  // Fetch detailed tax summary
  getTaxSummary: async (calculationId) => {
    try {
      const url = `${API_URL}${ENDPOINTS.TAX_SUMMARY.replace('{calc_id}', calculationId)}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error('Get tax summary error:', error);
      if (error.response) {
        throw new Error(error.response.data?.detail || 'Failed to retrieve tax summary');
      }
      throw new Error('Network error occurred while retrieving summary');
    }
  },

  // Fetch tax calculation history
  getTaxCalculationHistory: async (filters = {}) => {
    try {
      const response = await axios.get(`${API_URL}${ENDPOINTS.HISTORY}`, {
        params: filters,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error('Get tax history error:', error);
      if (error.response) {
        throw new Error(error.response.data?.detail || 'Failed to retrieve tax history');
      }
      throw new Error('Network error occurred while retrieving history');
    }
  },

  // Helper method to get state name from code
  getStateName: (stateCode) => {
    return TAX_CONSTANTS.SUPPORTED_STATES[stateCode]?.name || stateCode;
  },

  // Helper method to get state tax info
  getStateTaxInfo: (stateCode) => {
    return TAX_CONSTANTS.SUPPORTED_STATES[stateCode] || null;
  },

  // Helper method to get available tax years
  getAvailableTaxYears: () => {
    return TAX_CONSTANTS.TAX_YEARS.AVAILABLE;
  },

  // Helper method to get current tax year
  getCurrentTaxYear: () => {
    return TAX_CONSTANTS.TAX_YEARS.CURRENT;
  },

  // Helper method to get filing status info
  getFilingStatuses: () => {
    return TAX_CONSTANTS.FILING_STATUSES;
  },

  // Helper method to format currency
  formatCurrency: (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  },

  // Helper method to format percentage
  formatPercentage: (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value / 100);
  }
};

export default TaxCalculationService;
