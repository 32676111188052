import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { DollarSign, HelpCircle, TrendingUp, Briefcase, Plus, Minus } from 'lucide-react';
import '../../../../styles/TaxCalculator/steps/Income.css';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../../../components/ui/tooltip';
import { Card } from '../../../../components/ui/card';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend
} from 'recharts';
import Layout from '../shared/Layout';

const Income = ({ formData, setFormData, error }) => {
  const navigate = useNavigate();
  const { stateCode } = useParams();
  const [w2Forms, setW2Forms] = useState([{ box1: 0, box2: 0, box17: 0 }]);

  // Route guard - redirect if prerequisites not met
  useEffect(() => {
    if (!formData.state || !formData.year || !formData.filing_status) {
      navigate('/tax-calculator', { replace: true });
    }
  }, [formData.state, formData.year, formData.filing_status, navigate]);

  const handleIncomeChange = (field, value) => {
    const numValue = parseFloat(value) || 0;
    setFormData(prev => ({
      ...prev,
      income: {
        ...prev.income,
        [field]: numValue
      }
    }));
  };

  const addW2Form = () => {
    setW2Forms([...w2Forms, { box1: 0, box2: 0, box17: 0 }]);
  };

  const removeW2Form = (index) => {
    if (w2Forms.length > 1) {
      const newForms = w2Forms.filter((_, i) => i !== index);
      setW2Forms(newForms);
      updateTotalW2Income(newForms);
    }
  };

  const updateW2Form = (index, field, value) => {
    const numValue = parseFloat(value) || 0;
    const newForms = w2Forms.map((form, i) => {
      if (i === index) {
        return { ...form, [field]: numValue };
      }
      return form;
    });
    setW2Forms(newForms);
    updateTotalW2Income(newForms);
  };

  const updateTotalW2Income = (forms) => {
    const totalWages = forms.reduce((sum, form) => sum + (parseFloat(form.box1) || 0), 0);
    const totalFederalWithholding = forms.reduce((sum, form) => sum + (parseFloat(form.box2) || 0), 0);
    const totalStateWithholding = forms.reduce((sum, form) => sum + (parseFloat(form.box17) || 0), 0);

    setFormData(prev => ({
      ...prev,
      income: {
        ...prev.income,
        wages: totalWages,
        w2_info: forms
      },
      withholdings: {
        federal: totalFederalWithholding,
        state: totalStateWithholding
      }
    }));
  };

  const totalIncome = Object.values(formData.income)
    .filter(value => typeof value === 'number')
    .reduce((sum, val) => sum + (val || 0), 0);

  const incomeTypes = [
    {
      field: 'unemployment',
      label: 'Unemployment Compensation',
      tooltip: 'Income received from unemployment benefits',
      icon: Briefcase
    },
    {
      field: 'combat_pay',
      label: 'Combat Pay',
      tooltip: 'Military combat zone compensation',
      icon: DollarSign
    },
    {
      field: 'social_security',
      label: 'Social Security Benefits',
      tooltip: 'Social security benefits received',
      icon: DollarSign
    },
    {
      field: 'self_employment',
      label: 'Self Employment',
      tooltip: 'Income from business ownership, freelancing, or contract work',
      icon: TrendingUp
    },
    {
      field: 'investments',
      label: 'Investment Income',
      tooltip: 'Dividends, capital gains, and interest income',
      icon: TrendingUp
    },
    {
      field: 'retirement',
      label: 'Retirement Income',
      tooltip: 'Income from pensions, IRA distributions',
      icon: DollarSign
    },
    {
      field: 'other',
      label: 'Other Income',
      tooltip: 'Rental income, royalties, and other miscellaneous income',
      icon: DollarSign
    }
  ];

  const handleNext = () => {
    navigate(`/tax-calculator/${stateCode}/deductions`, { replace: true });
  };

  const handleBack = () => {
    navigate(`/tax-calculator/${stateCode}/basic-info`, { replace: true });
  };

  // Prepare data for pie chart
  const chartData = Object.entries(formData.income)
    .filter(([key, value]) => typeof value === 'number' && value > 0 && key !== 'w2_info')
    .map(([key, value]) => ({
      name: incomeTypes.find(type => type.field === key)?.label || key,
      value: value,
      percentage: (value / totalIncome * 100).toFixed(1)
    }));

  const COLORS = ['#60A5FA', '#34D399', '#FBBF24', '#A78BFA', '#F87171', '#6EE7B7', '#FCD34D'];

  return (
    <Layout formData={formData} setFormData={setFormData}>
      <motion.div 
        className="space-y-8"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="flex items-center justify-between mb-6">
          <div className="space-y-1">
            <h3 className="text-2xl font-bold text-white">Income Information</h3>
            <p className="text-gray-300">Enter your income from all sources</p>
          </div>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <HelpCircle className="text-gray-400" size={20} />
              </TooltipTrigger>
              <TooltipContent>
                <p className="max-w-xs">Include all sources of income for accurate tax calculation</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>

        {/* W-2 Forms Section */}
        <Card className="p-6 bg-gray-900/50 border-gray-700">
          <h4 className="text-lg font-semibold text-white mb-4">W-2 Information</h4>
          {w2Forms.map((form, index) => (
            <div key={index} className="mb-6 p-4 border border-gray-700 rounded-lg">
              <div className="flex justify-between items-center mb-4">
                <h5 className="text-white font-medium">W-2 Form #{index + 1}</h5>
                {index > 0 && (
                  <button
                    onClick={() => removeW2Form(index)}
                    className="text-red-400 hover:text-red-300 flex items-center"
                  >
                    <Minus size={16} className="mr-1" />
                    Remove
                  </button>
                )}
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="space-y-2">
                  <label className="text-sm text-gray-300">Box 1: Wages, tips, other compensation</label>
                  <div className="relative">
                    <DollarSign className="absolute left-3 top-3 text-gray-400" size={16} />
                    <input
                      type="number"
                      value={form.box1}
                      onChange={(e) => updateW2Form(index, 'box1', e.target.value)}
                      className="w-full pl-10 pr-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white"
                      min="0"
                      step="0.01"
                    />
                  </div>
                </div>

                <div className="space-y-2">
                  <label className="text-sm text-gray-300">Box 2: Federal income tax withheld</label>
                  <div className="relative">
                    <DollarSign className="absolute left-3 top-3 text-gray-400" size={16} />
                    <input
                      type="number"
                      value={form.box2}
                      onChange={(e) => updateW2Form(index, 'box2', e.target.value)}
                      className="w-full pl-10 pr-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white"
                      min="0"
                      step="0.01"
                    />
                  </div>
                </div>

                <div className="space-y-2">
                  <label className="text-sm text-gray-300">Box 17: State income tax withheld</label>
                  <div className="relative">
                    <DollarSign className="absolute left-3 top-3 text-gray-400" size={16} />
                    <input
                      type="number"
                      value={form.box17}
                      onChange={(e) => updateW2Form(index, 'box17', e.target.value)}
                      className="w-full pl-10 pr-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white"
                      min="0"
                      step="0.01"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}

          <button
            onClick={addW2Form}
            className="flex items-center text-blue-400 hover:text-blue-300"
          >
            <Plus size={16} className="mr-1" />
            Add Another W-2
          </button>
        </Card>

        {/* Income Distribution Chart */}
        {totalIncome > 0 && (
          <Card className="p-6 bg-gray-900/50 border-gray-700">
            <h4 className="text-lg font-semibold text-white mb-4">Income Distribution</h4>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={chartData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    label={({ name, percentage }) => `${name}: ${percentage}%`}
                  >
                    {chartData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Legend verticalAlign="bottom" height={36} />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </Card>
        )}

        {/* Additional Income Types */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {incomeTypes.map(({ field, label, tooltip, icon: Icon }) => (
            <TooltipProvider key={field}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <motion.div 
                    className="space-y-2"
                    whileHover={{ scale: 1.02 }}
                  >
                    <label className="flex items-center gap-2 text-sm font-medium text-gray-300">
                      {label}
                      <Icon className="text-gray-400" size={16} />
                    </label>
                    <div className="relative">
                      <DollarSign className="absolute left-3 top-3 text-gray-400" size={16} />
                      <input
                        type="number"
                        value={formData.income[field] || ''}
                        onChange={(e) => handleIncomeChange(field, e.target.value)}
                        className="w-full rounded-lg border border-gray-700 bg-gray-900/50 
                                 py-3 pl-10 pr-4 text-white placeholder-gray-500"
                        min="0"
                        step="0.01"
                        placeholder="0.00"
                      />
                    </div>
                  </motion.div>
                </TooltipTrigger>
                <TooltipContent>
                  <p className="max-w-xs">{tooltip}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ))}
        </div>

        {/* Total Income Summary */}
        <Card className="p-6 bg-blue-900/30 border-blue-500/50">
          <div className="flex justify-between items-center">
            <div>
              <h4 className="text-lg font-semibold text-blue-300">Total Annual Income</h4>
              <p className="text-sm text-blue-200/70">Sum of all income sources</p>
            </div>
            <div className="text-right">
              <p className="text-2xl font-bold text-blue-300">
                ${totalIncome.toLocaleString('en-US', { minimumFractionDigits: 2 })}
              </p>
              <p className="text-sm text-blue-200/70">Before taxes and deductions</p>
            </div>
          </div>
        </Card>

        {error && (
          <div className="mt-4 p-4 bg-red-900/50 border border-red-500 rounded-lg text-red-300">
            {error}
          </div>
        )}

        {/* Navigation */}
        <motion.div 
          className="flex justify-between mt-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          <motion.button
            onClick={handleBack}
            className="px-6 py-3 rounded-lg border border-gray-700 text-gray-300 
                     hover:bg-gray-800"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Back
          </motion.button>
          <motion.button
            onClick={handleNext}
            disabled={totalIncome <= 0}
            className="px-6 py-3 rounded-lg bg-blue-600 text-white hover:bg-blue-700 
                     disabled:opacity-50 disabled:cursor-not-allowed"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Continue to Deductions
          </motion.button>
        </motion.div>
      </motion.div>
    </Layout>
  );
};

export default Income;
