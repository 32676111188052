import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FiFileText, FiArrowLeft } from 'react-icons/fi';

// Components
import BasicInfo from './components/steps/BasicInfo';
import Income from './components/steps/Income';
import Deductions from './components/steps/Deductions';
import Credits from './components/steps/Credits';
import TaxResults from './components/TaxResults';
import StateSelection from './components/StateSelection';

// Services
import { TaxCalculationService } from '../../utils/services/taxCalculationService';

// Styles
import '../../styles/TaxCalculator.css';

const INITIAL_FORM_STATE = {
  year: new Date().getFullYear(),
  state: '',
  filing_status: '',
  income: {
    wages: 0,
    self_employment: 0,
    investments: 0,
    retirement: 0,
    unemployment: 0,
    combat_pay: 0,
    social_security: 0,
    other: 0,
    w2_info: [{ box1: 0, box2: 0, box17: 0 }]
  },
  deductions: {
    type: 'standard',
    itemized: {
      medical: 0,
      state_local_tax: 0,
      property_tax: 0,
      mortgage_interest: 0,
      charitable: 0
    },
    home_ownership: {
      mortgage_interest: 0,
      property_taxes: 0,
      home_insurance: 0,
      pmi_insurance: 0
    },
    student_loan_interest: 0,
    self_employed_health_insurance: 0,
    ira_deduction: 0
  },
  credits: {
    child_tax_credit: {
      num_children: 0
    },
    child_care: {
      expenses: 0
    },
    retirement_savings: {
      has_retirement_plan: false,
      contributions: 0
    },
    education: {
      type: "none",
      expenses: 0
    }
  },
  withholdings: {
    federal: 0,
    state: 0
  }
};

const TaxCalculator = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [calculationResults, setCalculationResults] = useState(null);
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);

  // Effect to sync state from URL and handle navigation guards
  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const stateIndex = pathParts.indexOf('tax-calculator') + 1;
    if (stateIndex < pathParts.length) {
      const stateCode = pathParts[stateIndex].toUpperCase();
      if (stateCode && (stateCode === 'CA' || stateCode === 'NC')) {
        setFormData(prev => ({
          ...prev,
          state: stateCode
        }));
      }
    }

    // Navigation guard for direct URL access
    const currentStep = pathParts[pathParts.length - 1];
    if (currentStep !== 'tax-calculator' && !formData.state) {
      navigate('/tax-calculator', { replace: true });
    }
  }, [location.pathname, formData.state, navigate]);

  const handleCalculateTax = async (data) => {
    setLoading(true);
    setError(null);
    try {
      let results;
      switch (data.state.toLowerCase()) {
        case 'ca':
          results = await TaxCalculationService.calculateCaliforniaTax(data);
          break;
        case 'nc':
          results = await TaxCalculationService.calculateNorthCarolinaTax(data);
          break;
        default:
          throw new Error('Unsupported state selected');
      }

      if (results && results.federal_tax) {
        setCalculationResults(results);
        navigate(`/tax-calculator/${data.state.toLowerCase()}/results`, { replace: true });
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        throw new Error('Invalid response format from server');
      }
    } catch (err) {
      console.error('Calculation error:', err);
      setError(err.message || 'Failed to calculate taxes');
    } finally {
      setLoading(false);
    }
  };

  const handleBackToChat = () => {
    // Show confirmation if form has data
    if (Object.values(formData.income).some(val => val > 0) || 
        formData.filing_status || 
        formData.deductions.type !== 'standard') {
      if (window.confirm('Are you sure you want to leave? Your progress will be lost.')) {
        navigate('/chat');
      }
    } else {
      navigate('/chat');
    }
  };

  const handleRecalculate = () => {
    setCalculationResults(null);
    setFormData(prev => ({
      ...INITIAL_FORM_STATE,
      year: prev.year // Preserve the selected year
    }));
    navigate('/tax-calculator', { replace: true });
  };

  const sharedProps = {
    formData,
    setFormData,
    loading,
    error
  };

  return (
    <div className="tax-calculator-page">
      <div className="galaxy-background" />
      <div className="galaxy-spiral" />

      <div className="tax-calculator-container">
        <motion.button
          onClick={handleBackToChat}
          className="tax-back-button"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <FiArrowLeft />
          <span>Back to Menu</span>
        </motion.button>

        <motion.div 
          className="tax-calculator-header"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.div 
            className="tax-calculator-logo"
            animate={{ rotate: 360 }}
            transition={{
              duration: 20,
              repeat: Infinity,
              ease: "linear"
            }}
          >
            <img src="/tax-logo-dark.svg" alt="Tax Calculator" />
          </motion.div>
          <h1>Tax Calculator</h1>
          <p>Calculate your federal and state taxes accurately</p>
        </motion.div>

        <AnimatePresence mode="wait">
          {error && (
            <motion.div 
              className="error-message"
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
            >
              <FiFileText size={20} />
              <span>{error}</span>
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence mode="wait">
          <Routes>
            <Route index element={<StateSelection {...sharedProps} />} />
            
            <Route path=":stateCode/*">
              <Route path="basic-info" element={<BasicInfo {...sharedProps} />} />
              <Route path="income" element={<Income {...sharedProps} />} />
              <Route path="deductions" element={<Deductions {...sharedProps} />} />
              <Route path="credits" element={<Credits {...sharedProps} onSubmit={handleCalculateTax} />} />
              <Route path="results" element={
                <TaxResults 
                  results={calculationResults}
                  onRecalculate={handleRecalculate}
                />
              } />
              <Route index element={<Navigate to="/tax-calculator" replace />} />
            </Route>

            <Route path="*" element={<Navigate to="/tax-calculator" replace />} />
          </Routes>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default TaxCalculator;
