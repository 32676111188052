import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Login from './pages/Login';
import Chat from './pages/Chat';
import Settings from './pages/Settings';
import History from './pages/History';
import Profile from './pages/Profile';
import About from './pages/About';
import TermsPrivacy from './pages/TermsPrivacy';
import JoinUs from './pages/JoinUs';
import Verification from './pages/Verification';
import Signup from './pages/Signup';
import TaxCalculator from './pages/TaxCalculator';
import StockAI from './pages/StockAI';
import Terms from './pages/Legal/Terms';
import Privacy from './pages/Legal/Privacy';

// Protected Route Component
const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('access_token');
    if (!token) {
        return <Navigate to="/" replace />;
    }
    return children;
};

const App = () => {
    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <Router>
                <Toaster
                    position="top-center"
                    toastOptions={{
                        style: {
                            background: '#1a1b4b',
                            color: '#ffffff',
                            border: '1px solid rgba(255, 255, 255, 0.1)',
                        },
                        success: {
                            iconTheme: {
                                primary: '#7B2FFF',
                                secondary: '#ffffff',
                            },
                        },
                        error: {
                            iconTheme: {
                                primary: '#ff4444',
                                secondary: '#ffffff',
                            },
                        },
                    }}
                />
                <Routes>
                    {/* Public Routes */}
                    <Route path="/" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/verify" element={<Verification />} />
                    <Route path="/legal/terms" element={<Terms />} />
                    <Route path="/legal/privacy" element={<Privacy />} />

                    {/* Protected Routes */}
                    <Route 
                        path="/chat" 
                        element={
                            <ProtectedRoute>
                                <Chat />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/settings" 
                        element={
                            <ProtectedRoute>
                                <Settings />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/history" 
                        element={
                            <ProtectedRoute>
                                <History />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/profile" 
                        element={
                            <ProtectedRoute>
                                <Profile />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/about" 
                        element={
                            <ProtectedRoute>
                                <About />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/terms-privacy" 
                        element={
                            <ProtectedRoute>
                                <TermsPrivacy />
                            </ProtectedRoute>
                        } 
                    />
                    <Route 
                        path="/join-us" 
                        element={
                            <ProtectedRoute>
                                <JoinUs />
                            </ProtectedRoute>
                        } 
                    />
                    
                    {/* Tax Calculator Routes */}
                    <Route 
                        path="/tax-calculator/*" 
                        element={
                            <ProtectedRoute>
                                <TaxCalculator />
                            </ProtectedRoute>
                        }
                    />
                    
                    {/* Stock AI Routes */}
                    <Route 
                        path="/stock-ai/*" 
                        element={
                            <ProtectedRoute>
                                <StockAI />
                            </ProtectedRoute>
                        } 
                    />

                    {/* Catch all route - redirect to login */}
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </Router>
        </GoogleOAuthProvider>
    );
};

export default App;
