import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { DollarSign, HelpCircle, Users, GraduationCap, Wallet, Plus, Minus } from 'lucide-react';
import '../../../../styles/TaxCalculator/steps/Credits.css';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '../../../../components/ui/tooltip';
import { Card } from '../../../../components/ui/card';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip as RechartsTooltip
} from 'recharts';
import Layout from '../shared/Layout';

const Credits = ({ formData, setFormData, onSubmit, loading, error }) => {
  const navigate = useNavigate();
  const { stateCode } = useParams();

  const handleCreditChange = (category, field, value) => {
    setFormData(prev => ({
      ...prev,
      credits: {
        ...prev.credits,
        [category]: {
          ...prev.credits[category],
          [field]: value
        }
      }
    }));
  };

  // Calculate potential credits
  const calculatePotentialCredits = () => {
    let total = 0;
    let breakdown = {};
    
    // Child Tax Credit
    const childTaxCredit = formData.credits.child_tax_credit.num_children * 2000;
    total += childTaxCredit;
    breakdown.childTaxCredit = childTaxCredit;
    
    // Child Care Credit
    const childCareExpenses = parseFloat(formData.credits.child_care.expenses) || 0;
    const childCareCredit = Math.min(childCareExpenses * 0.35, 
      formData.credits.child_tax_credit.num_children * 3000);
    total += childCareCredit;
    breakdown.childCareCredit = childCareCredit;
    
    // Education Credit
    let educationCredit = 0;
    if (formData.credits.education.type === 'american_opportunity') {
      educationCredit = Math.min(parseFloat(formData.credits.education.expenses) || 0, 2500);
    } else if (formData.credits.education.type === 'lifetime_learning') {
      educationCredit = Math.min((parseFloat(formData.credits.education.expenses) || 0) * 0.2, 2000);
    }
    total += educationCredit;
    breakdown.educationCredit = educationCredit;
    
    // Retirement Savings Credit
    let retirementCredit = 0;
    if (formData.credits.retirement_savings.has_retirement_plan) {
      retirementCredit = Math.min(
        (parseFloat(formData.credits.retirement_savings.contributions) || 0) * 0.5,
        1000
      );
    }
    total += retirementCredit;
    breakdown.retirementCredit = retirementCredit;
    
    return { total, breakdown };
  };

  const { total: potentialCredits, breakdown: creditsBreakdown } = calculatePotentialCredits();

  const creditData = [
    {
      category: 'Child Tax Credit',
      amount: creditsBreakdown.childTaxCredit,
      max: formData.credits.child_tax_credit.num_children * 2000
    },
    {
      category: 'Child Care',
      amount: creditsBreakdown.childCareCredit,
      max: formData.credits.child_tax_credit.num_children * 3000
    },
    {
      category: 'Education',
      amount: creditsBreakdown.educationCredit,
      max: formData.credits.education.type === 'american_opportunity' ? 2500 : 2000
    },
    {
      category: 'Retirement Savings',
      amount: creditsBreakdown.retirementCredit,
      max: 1000
    }
  ];

  const containerAnimation = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemAnimation = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  return (
    <Layout formData={formData} setFormData={setFormData}>
      <motion.div 
        className="space-y-8"
        variants={containerAnimation}
        initial="hidden"
        animate="show"
      >
        <div className="flex items-center justify-between mb-6">
          <div className="space-y-1">
            <h3 className="text-2xl font-bold text-white">Tax Credits</h3>
            <p className="text-gray-300">Enter your qualifying tax credits</p>
          </div>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <HelpCircle className="text-gray-400" size={20} />
              </TooltipTrigger>
              <TooltipContent>
                <div className="space-y-2">
                  <p className="text-sm">Tax credits directly reduce your tax liability dollar for dollar</p>
                  <ul className="text-xs space-y-1">
                    <li>• Non-refundable credits can't exceed your tax liability</li>
                    <li>• Refundable credits can result in a refund</li>
                    <li>• Subject to income limitations and phase-outs</li>
                  </ul>
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>

        {/* Child-Related Credits */}
        <motion.div variants={itemAnimation}>
          <Card className="p-6 bg-gray-900/50 border-gray-700">
            <div className="flex items-center gap-2 mb-4">
              <Users className="text-blue-400" size={24} />
              <h4 className="text-lg font-semibold text-white">Child-Related Credits</h4>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div className="space-y-2">
                      <label className="block text-sm font-medium text-gray-300">
                        Number of Qualifying Children
                      </label>
                      <div className="flex items-center space-x-2">
                        <input
                          type="number"
                          value={formData.credits.child_tax_credit.num_children || ''}
                          onChange={(e) => handleCreditChange('child_tax_credit', 'num_children', 
                            parseInt(e.target.value) || 0)}
                          className="w-full rounded-lg border border-gray-700 bg-gray-900/50 
                                   py-3 px-4 text-white placeholder-gray-500"
                          min="0"
                          placeholder="0"
                        />
                        <p className="text-sm text-gray-400">
                          x $2,000 each
                        </p>
                      </div>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p className="max-w-xs">$2,000 credit per qualifying child under 17</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>

              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div className="space-y-2">
                      <label className="block text-sm font-medium text-gray-300">
                        Child Care Expenses
                      </label>
                      <div className="relative">
                        <DollarSign className="absolute left-3 top-3 text-gray-400" size={16} />
                        <input
                          type="number"
                          value={formData.credits.child_care.expenses || ''}
                          onChange={(e) => handleCreditChange('child_care', 'expenses', 
                            parseFloat(e.target.value) || 0)}
                          className="w-full rounded-lg border border-gray-700 bg-gray-900/50 
                                   py-3 pl-10 pr-4 text-white placeholder-gray-500"
                          min="0"
                          step="0.01"
                          placeholder="0.00"
                        />
                      </div>
                      <p className="text-xs text-gray-400">
                        Up to 35% credit rate on qualifying expenses
                      </p>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p className="max-w-xs">Credit for child care expenses while working or looking for work</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </Card>
        </motion.div>

        {/* Education Credits */}
        <motion.div variants={itemAnimation}>
          <Card className="p-6 bg-gray-900/50 border-gray-700">
            <div className="flex items-center gap-2 mb-4">
              <GraduationCap className="text-blue-400" size={24} />
              <h4 className="text-lg font-semibold text-white">Education Credits</h4>
            </div>

            <div className="space-y-4">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div className="space-y-2">
                      <label className="block text-sm font-medium text-gray-300">Credit Type</label>
                      <select
                        value={formData.credits.education.type}
                        onChange={(e) => handleCreditChange('education', 'type', e.target.value)}
                        className="w-full rounded-lg border border-gray-700 bg-gray-900/50 
                                py-3 px-4 text-white"
                      >
                        <option value="none">None</option>
                        <option value="american_opportunity">American Opportunity Credit (up to $2,500)</option>
                        <option value="lifetime_learning">Lifetime Learning Credit (up to $2,000)</option>
                      </select>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <div className="space-y-2">
                      <p className="text-sm">Choose your education credit:</p>
                      <ul className="text-xs space-y-1">
                        <li>• American Opportunity: First 4 years of college</li>
                        <li>• Lifetime Learning: Any post-secondary education</li>
                      </ul>
                    </div>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>

              {formData.credits.education.type !== 'none' && (
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-300">Qualified Expenses</label>
                  <div className="relative">
                    <DollarSign className="absolute left-3 top-3 text-gray-400" size={16} />
                    <input
                      type="number"
                      value={formData.credits.education.expenses || ''}
                      onChange={(e) => handleCreditChange('education', 'expenses', 
                        parseFloat(e.target.value) || 0)}
                      className="w-full rounded-lg border border-gray-700 bg-gray-900/50 
                               py-3 pl-10 pr-4 text-white placeholder-gray-500"
                      min="0"
                      step="0.01"
                      placeholder="Qualified Education Expenses"
                    />
                  </div>
                  <p className="text-xs text-gray-400">
                    {formData.credits.education.type === 'american_opportunity'
                      ? 'Max credit: $2,500 (100% of first $2,000, 25% of next $2,000)'
                      : 'Max credit: $2,000 (20% of up to $10,000 in expenses)'}
                  </p>
                </div>
              )}
            </div>
          </Card>
        </motion.div>

        {/* Retirement Savings Credit */}
        <motion.div variants={itemAnimation}>
          <Card className="p-6 bg-gray-900/50 border-gray-700">
            <div className="flex items-center gap-2 mb-4">
              <Wallet className="text-blue-400" size={24} />
              <h4 className="text-lg font-semibold text-white">Retirement Savings Credit</h4>
            </div>

            <div className="space-y-4">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <label className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        checked={formData.credits.retirement_savings.has_retirement_plan}
                        onChange={(e) => handleCreditChange('retirement_savings', 
                          'has_retirement_plan', e.target.checked)}
                        className="rounded border-gray-700 bg-gray-900"
                      />
                      <span className="text-sm text-gray-300">Made retirement contributions</span>
                    </label>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p className="max-w-xs">Credit for contributions to qualified retirement accounts</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>

              {formData.credits.retirement_savings.has_retirement_plan && (
                <div className="space-y-2">
                  <label className="block text-sm font-medium text-gray-300">Contributions</label>
                  <div className="relative">
                    <DollarSign className="absolute left-3 top-3 text-gray-400" size={16} />
                    <input
                      type="number"
                      value={formData.credits.retirement_savings.contributions || ''}
                      onChange={(e) => handleCreditChange('retirement_savings', 
                        'contributions', parseFloat(e.target.value) || 0)}
                      className="w-full rounded-lg border border-gray-700 bg-gray-900/50 
                               py-3 pl-10 pr-4 text-white placeholder-gray-500"
                      min="0"
                      step="0.01"
                      placeholder="0.00"
                    />
                  </div>
                  <p className="text-xs text-gray-400">Up to 50% credit rate on contributions</p>
                </div>
              )}
            </div>
          </Card>
        </motion.div>

        {/* Credits Summary Chart */}
        <motion.div variants={itemAnimation}>
          <Card className="p-6 bg-gray-900/50 border-gray-700">
            <h4 className="text-lg font-semibold text-white mb-4">Potential Credits Summary</h4>
            <div className="h-64">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={creditData}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                  <XAxis dataKey="category" stroke="#9CA3AF" />
                  <YAxis stroke="#9CA3AF" />
                  <RechartsTooltip 
                    contentStyle={{ 
                      backgroundColor: '#1F2937', 
                      border: '1px solid #374151',
                      borderRadius: '0.5rem'
                    }}
                    formatter={(value, name, props) => [
                      `$${value.toLocaleString()}`,
                      `${name} (Max: $${props.payload.max.toLocaleString()})`
                    ]}
                  />
                  <Bar 
                    dataKey="amount" 
                    fill="#4F46E5"
                    radius={[4, 4, 0, 0]}
                    label={{ 
                      position: 'top',
                      fill: '#9CA3AF',
                      formatter: (value) => `$${value.toLocaleString()}`
                    }}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </motion.div>

        {/* Total Credits Summary */}
        <motion.div variants={itemAnimation}>
          <Card className="p-6 bg-indigo-900/30 border-indigo-500/50">
            <div className="flex justify-between items-center">
              <div>
                <h4 className="text-lg font-semibold text-indigo-300">Total Potential Credits</h4>
                <p className="text-sm text-indigo-200/70">
                  Subject to eligibility and income limitations
                </p>
              </div>
              <div className="text-right">
                <p className="text-2xl font-bold text-indigo-300">
                  ${potentialCredits.toLocaleString('en-US', { minimumFractionDigits: 2 })}
                </p>
              </div>
            </div>
          </Card>
        </motion.div>

        {/* State-Specific Credits Notice */}
        <motion.div variants={itemAnimation}>
          <Card className="p-4 bg-blue-900/30 border-blue-500/50">
            <div className="flex items-start gap-3">
              <HelpCircle className="text-blue-400 mt-1" size={20} />
              <div>
                <h4 className="text-sm font-medium text-blue-300">State Credits Available</h4>
                <p className="text-sm text-blue-200/70">
                  {stateCode === 'CA' 
                    ? "California offers additional credits including the Renter's Credit and Young Child Tax Credit." 
                    : 'North Carolina offers a limited Child Tax Credit based on income eligibility.'}
                </p>
              </div>
            </div>
          </Card>
        </motion.div>

        {error && (
          <div className="mt-4 p-4 bg-red-900/50 border border-red-500 rounded-lg text-red-300">
            {error}
          </div>
        )}

        {/* Navigation */}
        <motion.div 
          className="flex justify-between mt-8"
          variants={itemAnimation}
        >
          <motion.button
            onClick={() => navigate(`/tax-calculator/${stateCode}/deductions`)}
            className="px-6 py-3 rounded-lg border border-gray-700 text-gray-300 
                     hover:bg-gray-800"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Back
          </motion.button>
          <motion.button
            onClick={() => onSubmit(formData)}
            disabled={loading}
            className="px-6 py-3 rounded-lg bg-blue-600 text-white hover:bg-blue-700 
                     disabled:opacity-50"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {loading ? 'Calculating...' : 'Calculate Taxes'}
          </motion.button>
        </motion.div>
      </motion.div>
    </Layout>
  );
};

export default Credits;
