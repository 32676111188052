import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import '../../styles/Legal.css';

const Terms = () => {
    return (
        <div className="legal-page">
            <Link to="/" className="back-button">
                <ArrowLeft size={20} />
                Back to Home
            </Link>

            <div className="legal-container">
                <h1>Terms and Conditions</h1>
                <div className="legal-content">
                    <section>
                        <h2>1. Services Overview</h2>
                        <p>TaxMeAI provides AI-powered tax planning, financial analysis, and stock market insights. Our platform offers innovative solutions for:</p>
                        <ul>
                            <li>Automated tax calculations and optimization strategies</li>
                            <li>Real-time stock market analysis and recommendations</li>
                            <li>AI-driven financial planning and insights</li>
                            <li>Personalized investment strategies</li>
                        </ul>
                    </section>

                    <section>
                        <h2>2. User Accounts & Security</h2>
                        <p>By creating an account, users agree to:</p>
                        <ul>
                            <li>Provide accurate and complete registration information</li>
                            <li>Maintain the confidentiality of account credentials</li>
                            <li>Be at least 18 years old or have legal guardian consent</li>
                            <li>Notify us immediately of any unauthorized access</li>
                        </ul>
                    </section>

                    <section>
                        <h2>3. Financial Advice Disclaimer</h2>
                        <p>Important considerations regarding our services:</p>
                        <ul>
                            <li>Our AI insights are for informational purposes only</li>
                            <li>We do not provide professional tax or financial advice</li>
                            <li>Users should consult with qualified professionals for specific advice</li>
                            <li>Past performance does not guarantee future results</li>
                        </ul>
                    </section>

                    <section>
                        <h2>4. Data Privacy & Usage</h2>
                        <p>Our data handling practices include:</p>
                        <ul>
                            <li>Secure storage and processing of user information</li>
                            <li>Use of data for service improvement and personalization</li>
                            <li>No sharing of personal information with third parties without consent</li>
                            <li>Regular security audits and updates</li>
                        </ul>
                    </section>

                    <section>
                        <h2>5. User Responsibilities</h2>
                        <p>Users must agree to:</p>
                        <ul>
                            <li>Use services in compliance with all applicable laws</li>
                            <li>Not attempt to manipulate or abuse the system</li>
                            <li>Report any security vulnerabilities or bugs</li>
                            <li>Keep account information updated and accurate</li>
                        </ul>
                    </section>

                    <section>
                        <h2>6. Service Modifications</h2>
                        <p>TaxMeAI reserves the right to:</p>
                        <ul>
                            <li>Modify or discontinue services with appropriate notice</li>
                            <li>Update terms and conditions as needed</li>
                            <li>Suspend accounts for violations of these terms</li>
                            <li>Implement new features or requirements</li>
                        </ul>
                    </section>

                    <section>
                        <h2>7. Limitation of Liability</h2>
                        <p>Users acknowledge that:</p>
                        <ul>
                            <li>Services are provided "as is" without warranties</li>
                            <li>We are not liable for investment losses</li>
                            <li>Service interruptions may occur</li>
                            <li>Market data may have delays or inaccuracies</li>
                        </ul>
                    </section>

                    <section>
                        <h2>8. Contact Information</h2>
                        <p>For questions about these terms:</p>
                        <ul>
                            <li>Email: support@taxmeai.com</li>
                            <li>Website: www.taxmeai.com/contact</li>
                            <li>Address: [Your Business Address]</li>
                        </ul>
                    </section>
                </div>

                <div className="legal-footer">
                    <p>Last updated: December 9, 2024</p>
                    <p>
                        For more information, please review our{' '}
                        <Link to="/privacy" className="legal-link">Privacy Policy</Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Terms;
