import * as React from "react";
import { cn } from "../../utils/cn";

const Card = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "rounded-lg border border-gray-700 bg-gray-800/50 shadow-sm",
      className
    )}
    {...props}
  />
));
Card.displayName = "Card";

export { Card };
