import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import '../styles/About.css';

const GalaxyBackground = () => {
    React.useEffect(() => {
        const createStars = () => {
            const starsContainer = document.createElement('div');
            starsContainer.className = 'stars-container';
            
            for (let i = 0; i < 200; i++) {
                const star = document.createElement('div');
                star.className = 'star';
                const size = Math.random() * 2;
                
                star.style.width = `${size}px`;
                star.style.height = `${size}px`;
                star.style.left = `${Math.random() * 100}%`;
                star.style.top = `${Math.random() * 100}%`;
                star.style.setProperty('--duration', `${2 + Math.random() * 3}s`);
                
                starsContainer.appendChild(star);
            }
            
            document.querySelector('.galaxy-background').appendChild(starsContainer);
        };

        createStars();

        return () => {
            const container = document.querySelector('.stars-container');
            if (container) {
                container.remove();
            }
        };
    }, []);

    return (
        <>
            <div className="galaxy-background" />
            <div className="galaxy-spiral" />
        </>
    );
};

const About = () => {
    const navigate = useNavigate();

    return (
        <div className="about-page">
            <GalaxyBackground />
            
            <motion.button 
                className="back-button"
                onClick={() => navigate('/chat')}
                whileHover={{ x: -5 }}
            >
                <FiArrowLeft size={24} />
                <span>Back </span>
            </motion.button>

            <div className="about-container">
                <div className="about-header">
                    <motion.img 
                        src="/tax-logo-dark.svg"
                        alt="TaxMeAI Logo"
                        className="about-logo"
                        animate={{ rotate: 360 }}
                        transition={{
                            duration: 20,
                            repeat: Infinity,
                            ease: "linear"
                        }}
                    />
                    <h1>About TaxMeAI</h1>
                </div>

                <div className="about-content">
                    <section className="about-section">
                        <h2>Our Mission</h2>
                        <p>At TaxMeAI, we're revolutionizing tax preparation through artificial intelligence. Our mission is to make tax filing accessible, accurate, and stress-free for everyone, from individuals to large enterprises.</p>
                    </section>

                    <section className="about-section">
                        <h2>Who We Are</h2>
                        <p>Founded by a team of tax experts and AI specialists, TaxMeAI combines cutting-edge technology with deep tax knowledge. We understand the complexities of tax regulations and are committed to providing intelligent, reliable solutions.</p>
                    </section>

                    <section className="about-section">
                        <h2>Our Technology</h2>
                        <p>Our AI-powered platform leverages advanced machine learning algorithms to analyze tax documents, identify deductions, and ensure compliance with current tax laws. We continuously update our system to reflect the latest tax regulations and requirements.</p>
                    </section>

                    <section className="about-section">
                        <h2>Why Choose Us</h2>
                        <div className="features-grid">
                            <div className="feature">
                                <h3>Accuracy</h3>
                                <p>AI-powered calculations with multiple verification layers</p>
                            </div>
                            <div className="feature">
                                <h3>Security</h3>
                                <p>Bank-level encryption and data protection</p>
                            </div>
                            <div className="feature">
                                <h3>Expertise</h3>
                                <p>Combined knowledge of tax professionals and AI</p>
                            </div>
                            <div className="feature">
                                <h3>Support</h3>
                                <p>24/7 assistance from our dedicated team</p>
                            </div>
                        </div>
                    </section>

                    <section className="about-section">
                        <h2>Our Values</h2>
                        <ul className="values-list">
                            <li>
                                <h3>Innovation</h3>
                                <p>Continuously improving our AI to provide better service</p>
                            </li>
                            <li>
                                <h3>Integrity</h3>
                                <p>Maintaining the highest standards of honesty and ethics</p>
                            </li>
                            <li>
                                <h3>Accessibility</h3>
                                <p>Making tax services available to everyone</p>
                            </li>
                            <li>
                                <h3>Excellence</h3>
                                <p>Delivering outstanding results for every client</p>
                            </li>
                        </ul>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default About;
